html, body {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

@import "functions";
@import "fonts";

//@import "~owl.carousel/src/scss/owl.carousel";
//@import "~owl.carousel/src/scss/owl.theme.default";
//@import "~slick-carousel/slick/slick";
//@import "~slick-carousel/slick/slick-theme";
@import "~swiper/css/bundle";

$black: #0A0A0A;
$green-light: #03FF00;
$border-divider: solid px-to-vw(1px) rgba(255, 255, 255, 0.15);

.feature-banner {

    width: 100%;
    padding-top: 53.333%;
    background-color: $black;
    position: relative;
    overflow: hidden;

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
    }

    &__title {

        flex: 0 0 100%;
        /*height: px-to-vh(120px);*/
        border-bottom: $border-divider;
        display: flex;

        &-text {
            color: white;
            font-family: 'Circe';
            font-size: px-to-vw(69px);
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            padding: px-to-vw(25px) 0 px-to-vw(25px) px-to-vw(35px);
        }

        &-logos {
            
            flex: 0 0 px-to-vw(471px);
            width: px-to-vw(471px);
            margin-left: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: center;

            & .title-logos {

                &__lio {

                    flex: 0 0 50%;
                    border-left: $border-divider;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;

                    & a {
                        width: px-to-vw(74.5px);
                        height: px-to-vw(72px);
                        background: url('../img/sprite.svg#sprite-logo-view') no-repeat;
                        background-size: 100% 100%;
                    }

                }

                &__symbol {

                    flex: 0 0 50%;
                    border-left: $border-divider;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;

                    & a {
                        width: px-to-vw(163.5px);
                        height: px-to-vw(45.5px);
                        background: url('../img/sprite.svg#sprite-symbol-logo-view') no-repeat;
                        background-size: 100% 100%;

                    }

                }

            }

            & a {
                display: block;
                width: 100%;
                height: 100%;
            }

        }

    }

    &__shop {
        
        background-color: $black;
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 500;
        display: flex;
        flex-direction: column;
        display: block;
        transition: top 0.5s ease-out;

        &.--open {
            top: 0;
        }

        &-close {            

            position: absolute;
            width: px-to-vw(59px);
            height: px-to-vw(59px);
            top: px-to-vw(10px);
            right: px-to-vw(10px);
            border-radius: 100%;
            background-color: rgba(0, 0, 0, 0.30);
            z-index: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.--slider {
                top: px-to-vw(90px);
            }

            &:before {
                content: '';
                display: block;
                width: px-to-vw(33px);
                height: px-to-vw(33px);
                background: url('../img/sprite.svg#sprite-close-view') no-repeat;
                background-size: 100% 100%;
            }

            &:hover {

                background-color: $black;

                &:before {
                    background: url('../img/sprite.svg#sprite-close-hover-view') no-repeat;
                    background-size: 100% 100%;
                }

            }

        }

        &-footer {   
            width: 100%;         
            height: px-to-vw(152px);
        }

        &-slider {

            & .shop-slider {               

                &__title {

                    flex: 0 0 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    height: px-to-vw(82px);

                    &-text {
                        color: white;
                        text-align: center;
                        font-family: 'Circe';
                        font-size: px-to-vw(36px);
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: uppercase;
                    }

                    &-prev,
                    &-next {

                        height: 100%;
                        width: px-to-vw(101px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        transition: background-color 0.25s linear;
                        cursor: pointer;

                        &:before {
                            content: '';
                            display: block;
                            width: px-to-vw(31px);
                            height: px-to-vw(37px);
                        }

                        &:hover {
                            background-color: $green-light;
                        }

                    }

                    &-prev {

                        border-right: $border-divider;
                        margin-right: auto;

                        &:before {
                            background: url('../img/sprite.svg#sprite-arrow-prev-view') no-repeat;
                            background-size: 100% 100%;
                        }

                        &:hover:before {
                            background: url('../img/sprite.svg#sprite-arrow-prev-hover-view') no-repeat;
                            background-size: 100% 100%;
                        }

                    }

                    &-next {
                        
                        border-left: $border-divider;
                        margin-left: auto;

                        &:before {
                            background: url('../img/sprite.svg#sprite-arrow-next-view') no-repeat;  
                            background-size: 100% 100%;   
                        }

                        &:hover:before {
                            background: url('../img/sprite.svg#sprite-arrow-next-hover-view') no-repeat;  
                            background-size: 100% 100%;
                        }

                    }

                }

            }


        }

        &-content {

            width: 100%;
            height: px-to-vw(872px);
            background-color: white;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: flex-start;
            flex: 1;
            background-color: $black;

            &.swiper-slide {
                flex: 0 0 100%;
            }

            &.--1 {

                & .shop-content {

                    &__column {

                        &:nth-child(1),
                        &:nth-child(3) {
                            width: px-to-vw(531px);
                        }

                    }

                }

            }

            &.--2 {

                & .shop-content {
                    
                    &__column {

                        &:nth-child(1),
                        &:nth-child(3) {
                            width: px-to-vw(640px);
                        }

                    }

                }

            }

            &.--3 {

                & .shop-content {
                    
                    &__column {

                        &:nth-child(1),
                        &:nth-child(3) {
                            width: px-to-vw(531px);
                        }

                    }

                }

            }

            &.--4,
            &.--7 {

                & .shop-content {

                    &__column {

                        &:nth-child(1),
                        &:nth-child(3) {
                            width: 33.333%;
                        }

                    }

                }

            }

            &.--8 {

                & .shop-content {

                    &__column {

                        &:nth-child(1),
                        &:nth-child(2) {
                            width: px-to-vw(694px);
                        }

                        &:nth-child(3) {
                            flex: 1;
                        }

                    }

                }

            }

            &.--9 {

                & .shop-content {

                    &__column {

                        width: 25%;

                    }

                }

            }

            & .shop-content {

                &__column {

                    display: flex;
                    flex-direction: column;

                    &:nth-child(2) {
                        flex: 1;
                    }

                }

                &__column-item {

                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    padding: px-to-vw(13.5px) px-to-vw(35px) px-to-vw(35px) px-to-vw(30px);
                    position: relative;
                    background-color: white;

                    &.--shop-color-1 {

                        background-color: #E8E8E8;

                        /*&:hover {

                            & .column-item {

                                &__link {
                                    background-color: #E1E1E1;
                                }

                            }

                        }*/

                    }

                    &.--shop-color-2 {

                        background-color: #F4F4F4;

                        /*&:hover {

                            & .column-item {

                                &__link {
                                    background-color: #EDEDED;
                                }

                            }

                        }*/

                    }

                    & .column-item {

                        &__link {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 200;
                            transition: background-color .25s linear;
                        }

                        &__picture {

                            display: flex;
                            flex: 1;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            padding-bottom: px-to-vw(21.5px);

                            & picture,
                            & picture img {
                                display: block;
                                max-width: 100%;
                                margin: 0 auto;
                                transition: transform .25s linear;
                            }

                            &.--pic-1-1 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(390px);
                                }

                            }

                            &.--pic-1-2 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(210px);
                                }

                            }

                            &.--pic-1-3 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(462px);
                                }

                            }

                            &.--pic-1-4 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(347px);
                                }

                            }

                            &.--pic-1-5 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(407px);
                                }

                            }

                            &.--pic-2-1 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(483px);
                                }

                            }

                            &.--pic-2-2 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(331px);
                                }

                            }

                            &.--pic-2-3 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(236px);
                                }

                            }

                            &.--pic-3-1 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(398px);
                                }

                            }

                            &.--pic-3-2 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(284px);
                                }

                            }

                            &.--pic-3-3 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(612px);
                                }

                            }

                            &.--pic-3-4 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(318px);
                                }

                            }

                            &.--pic-3-5 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(98px);
                                }

                            }

                            &.--pic-2-1-1 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(426px);
                                }

                            }

                            &.--pic-2-1-2 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(230px);
                                }

                            }

                            &.--pic-2-1-3 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(465px);
                                }

                            }

                            &.--pic-2-1-4 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(241px);
                                }

                            }

                            &.--pic-2-1-5 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(280px);
                                }

                            }

                            &.--pic-2-1-6 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(305px);
                                }

                            }

                            &.--pic-2-1-7 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(283px);
                                }

                            }

                            &.--pic-2-1-8 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(202px);
                                }

                            }

                            &.--pic-2-1-9 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(167px);
                                }

                            }

                            &.--pic-2-1-10 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(286px);
                                }

                            }

                            &.--pic-2-2-1 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(408px);
                                }

                            }

                            &.--pic-2-2-2 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(209px);
                                }

                            }

                            &.--pic-2-2-3 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(285px);
                                }

                            }

                            &.--pic-2-2-4 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(205px);
                                }

                            }

                            &.--pic-2-2-5 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(302px);
                                }

                            }

                            &.--pic-2-2-6 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(332px);
                                }

                            }

                            &.--pic-2-2-7 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(536px);
                                }

                            }

                            &.--pic-2-2-8 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(227px);
                                }

                            }

                            &.--pic-2-2-9 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(437px);
                                }

                            }

                            &.--pic-3-1 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(216px);
                                }

                            }

                            &.--pic-3-2 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(512px);
                                }

                            }

                            &.--pic-3-3 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(407px);
                                }

                            }

                            &.--pic-3-4 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(382px);
                                }

                            }

                            &.--pic-3-5 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(406px);
                                }

                            }

                            &.--pic-3-6 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(226px);
                                }

                            }

                            &.--pic-3-7 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(262px);
                                }

                            }

                            &.--pic-3-8 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(205px);
                                }

                            }

                            &.--pic-3-9 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(274px);
                                }

                            }

                            &.--pic-3-10 {

                                & picture,
                                & picture img {
                                    max-width: px-to-vw(352px);
                                }

                            }

                            /*& picture.s img {
                                width: px-to-vw(612px);
                            }*/

                        }

                        &__content {

                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: flex-start;
                            height: px-to-vw(57px);
                            width: 100%;

                            &-name {

                                flex: 1;

                                & .content-name {

                                    &__category {
                                        color: #1D1D1D;
                                        font-family: 'Circe';
                                        font-size: px-to-vw(20px);
                                        font-style: normal;
                                        line-height: normal;
                                        font-weight: 300;
                                        margin-bottom: px-to-vw(5px);
                                    }

                                    &__title {
                                        color: #1D1D1D;
                                        font-family: 'Circe';
                                        font-size: px-to-vw(20px);
                                        font-style: normal;
                                        line-height: normal;
                                        font-weight: 600;
                                        text-transform: uppercase;
                                    }

                                }

                            }

                            &-buy {
                                flex: 0 0 px-to-vw(211px);
                                padding: px-to-vw(14px) 0 px-to-vw(13.5px) 0;
                                color: #F4F4F4;
                                text-align: center;
                                font-family: 'Circe';
                                font-size: px-to-vw(20px);
                                font-weight: 600;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: px-to-vw(0.4px);
                                text-transform: uppercase;
                                background-color: #1D1D1D;
                            }

                        }

                    }

                    &:hover  {

                        & .column-item {

                            &__link {
                                background-color: rgba(0, 0, 0, 0.03);
                            }

                            &__picture {

                                & img {
                                    transform: scale(1.1);
                                }

                            }

                        }

                    }

                }

            }

        }

    }

    &__list {

        &-item {

            display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
            justify-content: flex-start;

            & .list-item {                

                &__left,
                &__right {
                    flex: 0 0 px-to-vw(469px);
                    width: px-to-vw(469px);
                    /*height: px-to-vh(904px);*/
                }

                &__left {
                    border-right: $border-divider;
                    display: flex;
                    flex-direction: column;
                }

                &__right {
                    flex: 0 0 px-to-vw(471px);
                    width: px-to-vw(471px);
                    border-left: $border-divider;
                    display: flex;
                    flex-direction: column;                    
                }

                &__center {

                    flex: 0 0 px-to-vw(980px);
                    width: px-to-vw(980px);
                    /*border-left: $border-divider;
                    border-right: $border-divider;*/
                    position: relative;
                    
                    &:before {
                        content: '';
                        display: block;
                        width: 0;
                        height: 100%;                        
                        border-right: $border-divider;
                        position: absolute;
                        top: 0;
                        left: px-to-vw(92px);
                        z-index: 100;
                    }
                    
                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 0;                        
                        border-top: $border-divider;
                        position: absolute;
                        bottom: px-to-vw(92px);
                        left: 0;
                        z-index: 100;
                    }

                    & picture,
                    & picture img {
                        display: block;
                        width: px-to-vw(980px);
                        /*height: px-to-vh(904px);*/
                        max-width: 100%;
                    }
                    
                }

                &__content {

                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 400;
                    background-color: $black;
                    width: 100%;
                    height: 100%;
                    padding: px-to-vw(50px) px-to-vw(55px) px-to-vw(50px) px-to-vw(50px);
                    transition: top 0.5s ease-out;

                    &.--open {
                        top: 0;
                    }

                    &-close {

                        position: absolute;
                        width: px-to-vw(50px);
                        height: px-to-vw(50px);
                        top: px-to-vw(50px);
                        right: px-to-vw(50px);
                        z-index: 100;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:before {
                            content: '';
                            display: block;
                            width: px-to-vw(33px);
                            height: px-to-vw(33px);
                            background: url('../img/sprite.svg#sprite-close-view') no-repeat;
                            background-size: 100% 100%;

                        }

                        &:hover {
                            &:before {
                                background: url('../img/sprite.svg#sprite-close-hover-view') no-repeat;
                                background-size: 100% 100%;
                            }
                        }

                    }

                    &-heading {
                        width: 100%;
                        color: white;
                        font-family: 'Circe';
                        font-size: px-to-vw(35px);
                        font-style: normal;
                        font-weight: 300;
                        line-height: 137.143%;
                    }

                    &-divider {

                        width: 100%;
                        background-color: white;
                        height: px-to-vw(1px);
                        margin: px-to-vw(74px) 0 px-to-vw(50px) 0;

                        &.--20 {
                            margin: px-to-vw(50px) 0 px-to-vw(40px);
                        }

                        &.--30 {
                            margin: px-to-vw(20px) 0;
                        }

                    }

                    &-text {

                        width: 100%;
                        margin-bottom: px-to-vw(25px);

                        & p {

                            color: white;
                            font-family: 'Circe';
                            font-size: px-to-vw(22px);
                            font-style: normal;
                            font-weight: 300;
                            line-height: 122.727%;
                            margin-bottom: px-to-vw(20px);

                            &:last-child {
                                margin-bottom: 0;
                            }

                        }

                    }

                    &-footing {
                        color: #6C6C6C;
                        font-family: 'Circe';
                        font-size: px-to-vw(22px);
                        font-style: normal;
                        font-weight: 300;
                        line-height: 122.727%;
                    }


                }

                &__bag {

                    width: px-to-vw(92px);
                    height: px-to-vw(92px);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 230;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &:before {
                        content: '';
                        display: block;
                        width: px-to-vw(33px);
                        height: px-to-vw(39px);
                        background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                        background-size: 100% 100%;
                    }

                }

                &__tags {

                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 150;
                    opacity: 0;
                    transition: opacity 0.5s linear;

                    &:hover {
                        opacity: 1;
                    }

                    &-item {

                        width: px-to-vw(47px);
                        height: px-to-vw(47px);
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 100;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        & span.idle {
                            display: block;
                            width: 100%;
                            height: 100%;
                            background: url('../img/sprite.svg#sprite-tag-view') no-repeat;
                            background-size: 100% 100%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 102;
                            opacity: 1;
                            transition: opacity 0.5s ease-in;
                            cursor: pointer;
                        }

                        & span.active {
                            display: block;
                            width: 200%;
                            height: 200%;
                            background: url('../img/sprite.svg#sprite-tag-hover-view') no-repeat;
                            background-size: 100% 100%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transform-origin: center center;
                            z-index: 100;
                            opacity: 0;
                            transition: width 0.5s ease-in, height 0.5s ease-in, opacity 0.5s ease-in;

                        }

                        & span.text {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 101;
                            color: white;
                            text-shadow: px-to-vw(1px) px-to-vw(1px) px-to-vw(200px) black, 0px 0px px-to-vw(70px) rgba(0, 0, 0, 0.60), 0px 0px px-to-vw(50px) rgba(0, 0, 0, 0.25);
                            font-family: 'Circe';
                            font-size: px-to-vw(22px);
                            font-style: normal;
                            font-weight: 400;
                            line-height: 131.9%;
                            transition: opacity 0.25s ease-in;
                            opacity: 0;
                        }

                        & span.idle:hover {

                            opacity: 0;

                            & + span.active {
                                opacity: 1;
                                width: 100%;
                                height: 100%;
                            }

                            & + span.active + span.text {
                                opacity: 1;
                            }

                        }

                        &.--1 {
                                                      
                            top: px-to-vw(184px);
                            left: px-to-vw(279px);

                            & span.text {
                                width: px-to-vw(213px);
                                top: auto;
                                left: px-to-vw(-151px);
                                bottom: px-to-vw(-70px);
                            }

                        }

                        &.--2 {
                                                      
                            top: px-to-vw(536px);
                            left: px-to-vw(636px);

                            & span.text {
                                width: px-to-vw(174px);
                                top: px-to-vw(14px);
                                left: auto;
                                right: px-to-vw(-179px);
                            }

                        }

                        &.--3 {
                                                      
                            top: px-to-vw(716px);
                            left: px-to-vw(357px);

                            & span.text {
                                width: px-to-vw(182px);
                                top: px-to-vw(-53px);
                                left: px-to-vw(-133px);
                            }

                        }

                        &.--4 {
                                                      
                            top: px-to-vw(265px);
                            left: px-to-vw(547px);

                            & span.text {
                                width: px-to-vw(190px);
                                top: px-to-vw(11px);
                                left: auto;
                                right: px-to-vw(-206px);
                            }

                        }

                        &.--5 {
                                                      
                            top: px-to-vw(555px);
                            left: px-to-vw(662px);

                            & span.text {
                                width: px-to-vw(148px);
                                top: auto;
                                left: px-to-vw(52px);
                                bottom: px-to-vw(-57px);
                            }

                        }

                        &.--6 {
                                                      
                            top: px-to-vw(673px);
                            left: px-to-vw(290px);

                            & span.text {
                                width: px-to-vw(198px);
                                top: px-to-vw(54px);
                                left: px-to-vw(58px);
                            }

                        }
                        
                        &.--7 {
                                                      
                            top: px-to-vw(139px);
                            left: px-to-vw(379px);

                            & span.text {
                                width: px-to-vw(229px);
                                top: px-to-vw(-73px);
                                left: px-to-vw(-160px);
                            }

                        }
                        
                        &.--8 {
                                                       
                            top: px-to-vw(376px);
                            left: px-to-vw(172px);

                            & span.text {
                                width: px-to-vw(229px);
                                top: px-to-vw(-104px);
                                left: px-to-vw(-136px);
                            }

                        }
                        
                        &.--9 {
                                                       
                            top: px-to-vw(436px);
                            left: px-to-vw(512px);

                            & span.text {
                                width: px-to-vw(199px);
                                top: px-to-vw(-4px);
                                left: auto;
                                right: px-to-vw(-229px);
                            }

                        }
                        
                        &.--10 {
                                                       
                            top: px-to-vw(506px);
                            left: px-to-vw(298px);

                            & span.text {
                                width: px-to-vw(148px);
                                top: auto;
                                left: px-to-vw(-121px);
                                bottom: px-to-vw(-69px);
                            }

                        }
                        
                        &.--11 {
                                                       
                            top: px-to-vw(710px);
                            left: px-to-vw(408px);

                            & span.text {
                                width: px-to-vw(225px);
                                top: px-to-vw(26px);
                                left: auto;
                                right: px-to-vw(-236px);
                            }

                        }
                        
                        &.--12 {
                                                       
                            top: px-to-vw(127px);
                            left: px-to-vw(345px);

                            & span.text {
                                width: px-to-vw(225px);
                                top: px-to-vw(-17px);
                                left: px-to-vw(-146px);
                            }

                        }
                        
                        &.--13 {
                                                       
                            top: px-to-vw(275px);
                            left: px-to-vw(528px);

                            & span.text {
                                width: px-to-vw(225px);
                                top: px-to-vw(12px);
                                left: px-to-vw(72px);
                            }

                        }
                        
                        &.--14 {
                                                       
                            top: px-to-vw(346px);
                            left: px-to-vw(708px);

                            & span.text {
                                width: px-to-vw(225px);
                                top: px-to-vw(12px);
                                left: px-to-vw(78px);
                            }

                        }
                        
                        &.--15 {
                                                       
                            top: px-to-vw(470px);
                            left: px-to-vw(262px);

                            & span.text {
                                width: px-to-vw(225px);
                                top: px-to-vw(-70px);
                                left: px-to-vw(-196px);
                            }

                        }
                        
                        &.--16 {
                                                       
                            top: px-to-vw(556px);
                            left: px-to-vw(356px);

                            & span.text {
                                width: px-to-vw(96px);
                                top: px-to-vw(4px);
                                left: px-to-vw(-100px);
                            }

                        }
                        
                        &.--17 {
                                                       
                            top: px-to-vw(718px);
                            left: px-to-vw(630px);

                            & span.text {
                                width: px-to-vw(140px);
                                top: px-to-vw(-2px);
                                left: px-to-vw(77px);
                            }

                        }
                        
                        &.--18 {
                                                       
                            top: px-to-vw(790px);
                            left: px-to-vw(341px);

                            & span.text {
                                width: px-to-vw(210px);
                                top: px-to-vw(11px);
                                left: px-to-vw(59px);
                            }

                        }
                        
                        &.--19 {
                                                       
                            top: px-to-vw(235px);
                            left: px-to-vw(126px);

                            & span.text {
                                width: px-to-vw(210px);
                                top: px-to-vw(-49px);
                                left: px-to-vw(-94px);
                            }

                        }
                        
                        &.--20 {
                                                       
                            top: px-to-vw(271px);
                            left: px-to-vw(266px);

                            & span.text {
                                width: px-to-vw(210px);
                                top: px-to-vw(11px);
                                left: px-to-vw(59px);
                            }

                        }
                        
                        &.--21 {
                                                       
                            top: px-to-vw(480px);
                            left: px-to-vw(750px);

                            & span.text {
                                width: px-to-vw(180px);
                                top: px-to-vw(59px);
                                left: px-to-vw(7px);
                            }

                        }
                        
                        &.--22 {
                                                       
                            top: px-to-vw(503px);
                            left: px-to-vw(486px);

                            & span.text {
                                width: px-to-vw(158px);
                                top: px-to-vw(44px);
                                left: px-to-vw(-143px);
                            }

                        }
                        
                        &.--23 {
                                                       
                            top: px-to-vw(710px);
                            left: px-to-vw(577px);

                            & span.text {
                                width: px-to-vw(210px);
                                top: px-to-vw(11px);
                                left: px-to-vw(59px);
                            }

                        }
                        
                        &.--24 {
                                                       
                            top: px-to-vw(138px);
                            left: px-to-vw(481px);

                            & span.text {
                                width: px-to-vw(210px);
                                top: px-to-vw(32px);
                                left: px-to-vw(-122px);
                            }

                        }
                        
                        &.--25 {
                                                       
                            top: px-to-vw(410px);
                            left: px-to-vw(598px);

                            & span.text {
                                width: px-to-vw(210px);
                                top: px-to-vw(11px);
                                left: px-to-vw(59px);
                            }

                        }
                        
                        &.--26 {
                                                       
                            top: px-to-vw(654px);
                            left: px-to-vw(487px);

                            & span.text {
                                width: px-to-vw(210px);
                                top: px-to-vw(11px);
                                left: px-to-vw(59px);
                            }

                        }
                        
                        &.--27 {
                                                       
                            top: px-to-vw(120px);
                            left: px-to-vw(430px);

                            & span.text {
                                width: px-to-vw(210px);
                                top: px-to-vw(-6px);
                                left: px-to-vw(59px);
                            }

                        }
                        
                        &.--28 {
                                                       
                            top: px-to-vw(243px);
                            left: px-to-vw(454px);

                            & span.text {
                                width: px-to-vw(230px);
                                top: px-to-vw(11px);
                                left: px-to-vw(59px);
                            }

                        }
                        
                        &.--29 {
                                                       
                            top: px-to-vw(314px);
                            left: px-to-vw(356px);

                            & span.text {
                                width: px-to-vw(210px);
                                top: px-to-vw(-45px);
                                left: px-to-vw(-98px);
                            }

                        }
                        
                        &.--30 {
                                                       
                            top: px-to-vw(594px);
                            left: px-to-vw(571px);

                            & span.text {
                                width: px-to-vw(210px);
                                top: px-to-vw(11px);
                                left: px-to-vw(59px);
                            }

                        }
                        
                        &.--31 {
                                                       
                            top: px-to-vw(654px);
                            left: px-to-vw(307px);

                            & span.text {
                                width: px-to-vw(210px);
                                top: px-to-vw(-21px);
                                left: px-to-vw(-141px);
                            }

                        }
                        
                    }

                }

                &__about {

                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    &-text {
                        flex: 1;
                        width: 100%;
                        color: white;
                        font-family: 'Circe';
                        font-size: px-to-vw(22px);
                        font-weight: 300;
                        line-height: 122.727%;
                        padding: px-to-vw(25px) px-to-vw(30px);
                        background-color: $black;
                        cursor: url('../img/svg/cursor-grab.svg'), grab;
                    }

                    &-buttons {

                        width: 100%;
                        border-top: $border-divider;
                        border-bottom: $border-divider;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: stretch;
                        justify-content: flex-start;
                        margin-top: auto;
                        background-color: $black;

                        & .about-buttons {

                            &__prev,
                            &__next {

                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;
                                padding: px-to-vw(25px) px-to-vw(43px);
                                border-right: $border-divider;
                                transition: background-color 0.25s linear;
                                cursor: pointer;

                                &:before {
                                    content: '';
                                    display: block;
                                    width: px-to-vw(31px);
                                    height: px-to-vw(37px);
                                }

                                &:hover {
                                    background-color: $green-light;
                                }

                            }

                            &__prev:before {
                                background: url('../img/sprite.svg#sprite-arrow-prev-view') no-repeat;
                                background-size: 100% 100%;
                            }

                            &__next:before {
                                background: url('../img/sprite.svg#sprite-arrow-next-view') no-repeat;  
                                background-size: 100% 100%;                              
                            }

                            &__prev:hover:before {
                                background: url('../img/sprite.svg#sprite-arrow-prev-hover-view') no-repeat;
                                background-size: 100% 100%;
                            }

                            &__next:hover:before {
                                background: url('../img/sprite.svg#sprite-arrow-next-hover-view') no-repeat;  
                                background-size: 100% 100%;
                            }

                            &__more {

                                flex: 1;
                                text-align: center;
                                color: white;
                                font-family: 'Circe';
                                font-size: px-to-vw(22px);
                                font-weight: 600;
                                line-height: 122.727%;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: background-color 0.25s linear, color 0.25s linear;

                                &:hover {
                                    background-color: $green-light;
                                    color: $black;
                                }

                            }

                        }
                        
                    }

                }

                &__rail {

                    width: 100%;
                    margin-top: auto;
                    position: relative;

                    & > picture,
                    & > picture img {
                        display: block;
                        width: 100%;
                    }

                    &-picture {

                        display: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 110;

                        & picture,
                        & picture img {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }

                    }

                    &-adv {
                        position: absolute;
                        bottom: 0;
                        min-height: px-to-vw(58px);
                        max-height: 100%;
                        width: 100%;
                        color: #fff;
                        font-family: "Circe";
                        font-size: px-to-vw(9px);
                        font-weight: 300;
                        line-height: 122.727%;
                        padding: 1.3020833333vw 1.5625vw;
                        background-color: rgba(9,9,9,.5);
                        z-index: 200; 
                    }

                    &:hover .list-item__rail-picture.--active {
                        display: block;
                    }

                    & .list-item__rail-picture.--hover,
                    &:hover .list-item__rail-picture.--active.--hover {
                        display: block;
                        z-index: 115;
                    }

                    &-nav {

                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 120;

                        & .rail-nav {

                            &__item {

                                display: block;
                                position: absolute;
                                z-index: 120;
                                top: 0px;
                                left: 0px;
                                cursor: pointer;

                                &.--1 {
                                    width: px-to-vw(162px);
                                    height: px-to-vw(383px);
                                    top: px-to-vw(55px);
                                    left: px-to-vw(20px);
                                    z-index: 130;
                                }

                                &.--2 {
                                    width: px-to-vw(221px);
                                    height: px-to-vw(490px);
                                    top: px-to-vw(67px);
                                    left: px-to-vw(103px);
                                }

                                &.--3 {
                                    width: px-to-vw(175px);
                                    height: px-to-vw(488px);
                                    top: px-to-vw(75px);
                                    left: px-to-vw(276px);
                                    z-index: 130;
                                }

                                &.--4 {
                                    width: px-to-vw(172px);
                                    height: px-to-vw(325px);
                                    top: px-to-vw(99px);
                                    left: px-to-vw(38px);
                                    z-index: 130;
                                }

                                &.--5 {
                                    width: px-to-vw(242px);
                                    height: px-to-vw(363px);
                                    top: px-to-vw(71px);
                                    left: px-to-vw(209px);
                                }

                            }

                        }

                    }

                }

                &__look {
                    
                    cursor: url('../img/svg/cursor-grab.svg'), grab;

                    & picture,
                    & picture img {
                        display: block;
                        width: 100%;
                    }

                }

                &__onexclusive {

                    flex: 1;
                    width: 100%;
                    padding-left: px-to-vw(35px);
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-start;
                    text-decoration: none;
                    color: white;
                    transition: background-color 0.25s linear, color 0.25s linear;
                    cursor: pointer;
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        width: px-to-vw(106px);
                        height: 100%;
                        background: url('../img/sprite.svg#sprite-hash-view') no-repeat;
                        margin-right: px-to-vw(29px);
                        background-size: 100% 100%;
                    }

                    &-container {
                    }

                    &-link {
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 100;
                    }

                    &-contest {
                        font-family: 'Circe';
                        font-size: px-to-vw(22px);
                        font-style: normal;
                        font-weight: 600;
                        line-height: 122.727%;
                        letter-spacing: px-to-vw(0.44px);
                    }

                    &-text {
                        text-align: center;
                        font-family: 'Circe';
                        font-size: px-to-vw(29.5px);
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: uppercase;
                    }

                }

                &__onexclusive:hover {

                    background-color: $green-light;
                    color: #040506;

                    &:before {
                        background: url('../img/sprite.svg#sprite-hash-hover-view') no-repeat;
                        background-size: 100% 100%;
                    }

                }

            }

        }

    }

    /*&__description {
        width: 100%;
        height: px-to-vh(332px);
    }

    &__nav {
        width: 100%;
        height: px-to-vh(572px);
    }*/

}

.billboard-banner {

    width: 100%;
    padding-top: 19.84126984126984%;
    background-color: $black;
    position: relative;
    overflow: hidden;

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
    }

    &__list {

        height: 100%;

        &-item {

            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: flex-start;
            position: relative;

            & .list-item {

                &__column {

                    position: relative;

                    &.--1 {
                        width: px-to-vw(790px, 1260px);
                        flex: 0 0 px-to-vw(790px, 1260px);
                        display: flex;
                        flex-wrap: wrap;
                        align-items: stretch;
                        justify-content: flex-start;
                        border-right: $border-divider;
                    }

                    &.--2 {
                        width: px-to-vw(280px, 1260px);
                        flex: 0 0 px-to-vw(280px, 1260px);
                        border-right: $border-divider;
                    }

                    &.--3 {
                        flex: 1;
                    }

                }

                &__content {

                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 400;
                    background-color: $black;
                    width: 100%;
                    height: 100%;
                    padding: px-to-vw(12px, 1260px) px-to-vw(67px, 1260px) px-to-vw(6px, 1260px) px-to-vw(39px, 1260px);
                    transition: top 0.5s ease-out;

                    &.--open {
                        top: 0;
                    }

                    &-close {

                        position: absolute;
                        width: px-to-vw(38px, 1260px);
                        height: px-to-vw(38px, 1260px);
                        top: px-to-vw(12px, 1260px);
                        right: px-to-vw(12px, 1260px);
                        z-index: 100;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:before {
                            content: '';
                            display: block;
                            width: px-to-vw(26px, 1260px);
                            height: px-to-vw(26px, 1260px);
                            background: url('../img/sprite.svg#sprite-close-view') no-repeat;
                            background-size: 100% 100%;

                        }

                        &:hover {
                            &:before {
                                background: url('../img/sprite.svg#sprite-close-hover-view') no-repeat;
                                background-size: 100% 100%;
                            }
                        }

                    }

                    &-heading {
                        width: 100%;
                        color: white;
                        font-family: 'Circe';
                        font-size: px-to-vw(16px, 1260px);
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }

                    &-divider {
                        width: 100%;
                        background-color: white;
                        height: px-to-vw(1px, 1260px);
                        margin: px-to-vw(12px, 1260px) 0 px-to-vw(12px, 1260px) 0;
                    }

                    &-text {
                        width: 100%;
                        color: white;
                        font-family: 'Circe';
                        font-size: px-to-vw(12px, 1260px);
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        margin-bottom: px-to-vw(10px, 1260px);
                    }

                    &-footing {
                        color: #6C6C6C;
                        font-family: 'Circe';
                        font-size: px-to-vw(12px, 1260px);
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }


                }

                &__title {

                    flex: 0 0 100%;
                    color: white;
                    font-family: 'Circe';
                    font-size: px-to-vw(44px, 1260px);
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                    padding: px-to-vw(15px, 1260px) px-to-vw(20px, 1260px) px-to-vw(8px, 1260px) px-to-vw(20px, 1260px);
                    border-bottom: $border-divider;

                    &.--40 {
                        font-size: px-to-vw(40px, 1260px);
                        padding-bottom: px-to-vw(11px, 1260px);
                    }

                }

                &__left {
                    display: flex;
                    flex: 0 0 px-to-vw(414px, 1260px);
                    width: px-to-vw(414px, 1260px);
                    flex-direction: column;
                    border-right: $border-divider;
                }

                &__right {
                    border-right: $border-divider;
                    flex: 1;

                }

                &__text {
                    color: white;
                    font-family: 'Circe';
                    font-size: px-to-vw(16px, 1260px);
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    padding: px-to-vw(10px, 1260px) px-to-vw(12px, 1260px) px-to-vw(15px, 1260px) px-to-vw(20px, 1260px);
                }

                &__buttons {

                    height: px-to-vw(38px, 1260px);
                    border-top: $border-divider;
                    margin-top: auto;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: stretch;
                    justify-content: flex-start;                    

                    &-prev,
                    &-next {

                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        width: px-to-vw(67px, 1260px);
                        border-right: $border-divider;
                        transition: background-color 0.25s linear;
                        cursor: pointer;

                        &:before {
                            content: '';
                            display: block;
                            width: px-to-vw(17px, 1260px);
                            height: px-to-vw(20.3px, 1260px);
                        }

                        &:hover {
                            background-color: $green-light;
                        }

                    }

                    &-prev {                        
                        width: px-to-vw(63px, 1260px);
                    }

                    &-prev:before {
                        background: url('../img/sprite.svg#sprite-arrow-prev-view') no-repeat;
                        background-size: 100% 100%;
                    }

                    &-next:before {
                        background: url('../img/sprite.svg#sprite-arrow-next-view') no-repeat;    
                        background-size: 100% 100%;                            
                    }

                    &-prev:hover:before {
                        background: url('../img/sprite.svg#sprite-arrow-prev-hover-view') no-repeat;
                        background-size: 100% 100%;
                    }

                    &-next:hover:before {
                        background: url('../img/sprite.svg#sprite-arrow-next-hover-view') no-repeat;  
                        background-size: 100% 100%;
                    }

                    &-more {

                        flex: 1;
                        text-align: center;
                        color: white;
                        font-family: 'Circe';
                        font-size: px-to-vw(16px, 1260px);
                        font-weight: 500;
                        line-height: normal;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: background-color 0.25s linear, color 0.25s linear;

                        &:hover {
                            background-color: $green-light;
                            color: $black;
                        }

                    }

                }

                &__rail {

                    width: 100%;
                    height: 100%;
                    margin-top: auto;
                    position: relative;

                    & > picture,
                    & > picture img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    &-adv {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 42%;
                        color: #fff;
                        font-family: "Circe";
                        font-size: px-to-vw(4px, 1260px);
                        font-weight: 300;
                        line-height: 122.727%;
                        padding: px-to-vw(5px, 1260px) px-to-vw(5px, 1260px) px-to-vw(2px, 1260px);
                        background-color: rgba(9,9,9,.5);
                        z-index: 200; 
                    }

                    &-picture {

                        display: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 110;

                        & picture,
                        & picture img {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }

                    }

                    &:hover .list-item__rail-picture.--active {
                        display: block;
                    }

                    & .list-item__rail-picture.--hover,
                    &:hover .list-item__rail-picture.--active.--hover {
                        display: block;
                        z-index: 115;
                    }

                    &-nav {

                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 120;

                        & .rail-nav {

                            &__item {

                                display: block;
                                position: absolute;
                                height: 100%;
                                z-index: 120;
                                top: 0px;
                                left: 0px;
                                cursor: pointer;

                                &.--1 {
                                    width: px-to-vw(137px, 1260px);
                                    height: 100%;
                                    z-index: 130;
                                }

                                &.--2 {
                                    width: px-to-vw(109px, 1260px);
                                    left: px-to-vw(136px, 1260px);
                                }

                                &.--3 {
                                    width: px-to-vw(110px, 1260px);
                                    left: px-to-vw(244px, 1260px);
                                    z-index: 130;
                                }

                                &.--4 {
                                    width: px-to-vw(117px, 1260px);
                                    left: px-to-vw(54px, 1260px);
                                    height: 100%;
                                    z-index: 130;
                                }

                                &.--5 {
                                    width: px-to-vw(177px, 1260px);
                                    left: px-to-vw(171px, 1260px);
                                }

                            }

                        }

                    }

                }

                &__main-picture {

                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 110;
                    height: 100%;
                    width: px-to-vw(240px, 1260px);
                    border-left: $border-divider;
                    border-right: $border-divider;

                    & picture,
                    & picture img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                }

                &__tags {

                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 150;
                    opacity: 0;
                    transition: opacity 0.5s linear;

                    &:hover {
                        opacity: 1;
                    }

                    &-item {

                        width: px-to-vw(30px, 1260px);
                        height: px-to-vw(30px, 1260px);
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 100;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        & span.idle {
                            display: block;
                            width: 100%;
                            height: 100%;
                            background: url('../img/sprite.svg#sprite-tag-view') no-repeat;
                            background-size: 100% 100%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 102;
                            opacity: 1;
                            transition: opacity 0.5s ease-in;
                            cursor: pointer;
                        }

                        & span.active {
                            display: block;
                            width: 200%;
                            height: 200%;
                            background: url('../img/sprite.svg#sprite-tag-hover-view') no-repeat;
                            background-size: 100% 100%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transform-origin: center center;
                            z-index: 100;
                            opacity: 0;
                            transition: width 0.5s ease-in, height 0.5s ease-in, opacity 0.5s ease-in;

                        }

                        & span.idle:hover,
                        & span.idle:active {

                            opacity: 0;

                            & + span.active {
                                opacity: 1;
                                width: 100%;
                                height: 100%;
                            }

                        }

                        &.--1 {                                                      
                            top: px-to-vw(32px, 1260px);
                            left: px-to-vw(56px, 1260px);
                        }

                        &.--2 {                                                      
                            top: px-to-vw(121px, 1260px);
                            left: px-to-vw(143px, 1260px);
                        }

                        &.--3 {                                                      
                            top: px-to-vw(154px, 1260px);
                            left: px-to-vw(82px, 1260px);
                        }

                        &.--4 {                                                      
                            top: px-to-vw(70px, 1260px);
                            left: px-to-vw(131px, 1260px);
                        }

                        &.--5 {                                                      
                            top: px-to-vw(113px, 1260px);
                            left: px-to-vw(29px, 1260px);
                        }

                        &.--6 {                                                      
                            top: px-to-vw(181px, 1260px);
                            left: px-to-vw(67px, 1260px);
                        }
                        
                        &.--7 {
                            top: px-to-vw(30px, 1260px);
                            left: px-to-vw(94px, 1260px);
                        }
                        
                        &.--8 {                                                       
                            top: px-to-vw(99px, 1260px);
                            left: px-to-vw(18px, 1260px);
                        }
                        
                        &.--9 {                                                       
                            top: px-to-vw(132px, 1260px);
                            left: px-to-vw(136px, 1260px);
                        }
                        
                        &.--10 {                                                       
                            top: px-to-vw(160px, 1260px);
                            left: px-to-vw(61px, 1260px);
                        }
                        
                        &.--11 {                                                       
                            top: px-to-vw(212px, 1260px);
                            left: px-to-vw(103px, 1260px);
                        }
                        
                        &.--12 {                                                       
                            top: px-to-vw(30px, 1260px);
                            left: px-to-vw(73px, 1260px);
                        }
                        
                        &.--13 {                                                       
                            top: px-to-vw(73px, 1260px);
                            left: px-to-vw(120px, 1260px);
                        }
                        
                        &.--14 {                                                       
                            top: px-to-vw(89px, 1260px);
                            left: px-to-vw(176px, 1260px);
                        }
                        
                        &.--15 {                                                       
                            top: px-to-vw(122px, 1260px);
                            left: px-to-vw(47px, 1260px);
                        }
                        
                        &.--16 {                                                       
                            top: px-to-vw(144px, 1260px);
                            left: px-to-vw(78px, 1260px);
                        }
                        
                        &.--17 {                                                       
                            top: px-to-vw(202px, 1260px);
                            left: px-to-vw(67px, 1260px);
                        }
                        
                        &.--18 {                                                       
                            top: px-to-vw(72px, 1260px);
                            left: px-to-vw(4px, 1260px);
                        }
                        
                        &.--19 {                                                       
                            top: px-to-vw(82px, 1260px);
                            left: px-to-vw(41px, 1260px);
                        }
                        
                        &.--20 {                                                       
                            top: px-to-vw(126px, 1260px);
                            left: px-to-vw(126px, 1260px);
                        }
                        
                        &.--21 {                                                       
                            top: px-to-vw(123px, 1260px);
                            left: px-to-vw(198px, 1260px);
                        }
                        
                        &.--22 {                                                       
                            top: px-to-vw(189px, 1260px);
                            left: px-to-vw(125px, 1260px);
                        }
                        
                        &.--23 {                                                       
                            top: px-to-vw(50px, 1260px);
                            left: px-to-vw(92px, 1260px);
                        }
                        
                        &.--24 {                                                       
                            top: px-to-vw(137px, 1260px);
                            left: px-to-vw(54px, 1260px);
                        }
                        
                        &.--25 {                                                       
                            top: px-to-vw(145px, 1260px);
                            left: px-to-vw(133px, 1260px);
                        }
                        
                        &.--26 {                                                       
                            top: px-to-vw(52px, 1260px);
                            left: px-to-vw(111px, 1260px);
                        }
                        
                        &.--27 {                                                       
                            top: px-to-vw(120px, 1260px);
                            left: px-to-vw(116px, 1260px);
                        }
                        
                        &.--28 {                                                       
                            top: px-to-vw(161px, 1260px);
                            left: px-to-vw(64px, 1260px);
                        }
                        
                        &.--29 {                                                       
                            top: px-to-vw(199px, 1260px);
                            left: px-to-vw(134px, 1260px);
                        }
                        
                    }

                } 

                &__shop {

                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    border-top: $border-divider;
                    height: px-to-vw(38px, 1260px);

                    &-icon {
                        position: absolute;
                        z-index: 100;
                        top: 50%;
                        left: px-to-vw(10px, 1260px);
                        transform: translateY(-50%);
                        width: px-to-vw(18.12px, 1260px);
                        height: px-to-vw(21.41px, 1260px);
                        background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                        background-size: 100% 100%;
                        cursor: pointer;
                    }

                }

                &__logos {

                    position: absolute;
                    z-index: 100;
                    top: px-to-vw(15px, 1260px);
                    left: 0;
                    width: 100%;

                    &-lio {
                        position: absolute;
                        z-index: 100;
                        top: 0;
                        right: px-to-vw(13px, 1260px);
                        width: px-to-vw(33px, 1260px);
                        height: px-to-vw(32px, 1260px);
                        background: url('../img/sprite.svg#sprite-logo-view') no-repeat;
                        background-size: 100% 100%;
                    }

                    &-symbol {
                        position: absolute;
                        z-index: 100;
                        top: 0;
                        left: px-to-vw(16px, 1260px);
                        width: px-to-vw(100px, 1260px);
                        height: px-to-vw(28px, 1260px);
                        background: url('../img/sprite.svg#sprite-symbol-logo-view') no-repeat;
                        background-size: 100% 100%;
                    }

                    & a {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 100;
                    }

                }

                &__onexclusive {

                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    z-index: 100;
                    /*padding-left: px-to-vw(25px, 1260px);*/
                    height: px-to-vw(37px, 1260px);
                    border-top: $border-divider;
                    cursor: pointer;
                    color: white;
                    transition: background-color 0.25s linear, color 0.25s linear;

                    &:before {
                        content: '';
                        display: block;
                        width: px-to-vw(44px, 1260px);
                        height: px-to-vw(38px, 1260px);
                        background: url('../img/sprite.svg#sprite-hash-view') no-repeat;
                        margin-right: px-to-vw(7px);
                        background-size: 100% 100%;
                    }

                    &-container {
                    }

                    &-link {
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 100;
                    }

                    &-contest {
                        font-family: 'Circe';
                        font-size: px-to-vw(10px, 1260px);
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: px-to-vw(0.2px, 1260px);
                    }

                    &-text {
                        font-family: 'Circe';
                        font-size: px-to-vw(14px, 1260px);
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: uppercase;
                    }

                    &:hover {

                        background-color: $green-light;
                        color: $black;

                        &:before {
                            background: url('../img/sprite.svg#sprite-hash-hover-view') no-repeat;
                            background-size: 100% 100%;
                        }

                    }

                }

            }

        }

    }    

    &__shop {
        
        background-color: $black;
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 500;
        display: block;
        transition: top 0.5s ease-out;

        &.--open {
            top: 0;
        }

        &-close {            

            position: absolute;
            width: px-to-vw(30px, 1260px);
            height: px-to-vw(30px, 1260px);
            top: px-to-vw(10px, 1260px);
            right: px-to-vw(10px, 1260px);
            border-radius: 100%;
            background-color: rgba(0, 0, 0, 0.30);
            z-index: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:before {
                content: '';
                display: block;
                width: px-to-vw(18px, 1260px);
                height: px-to-vw(18px, 1260px);
                background: url('../img/sprite.svg#sprite-close-view') no-repeat;
                background-size: 100% 100%;
            }

            &:hover {

                background-color: $black;

                &:before {
                    background: url('../img/sprite.svg#sprite-close-hover-view') no-repeat;
                    background-size: 100% 100%;
                }

            }

        }

        &-slider {

            & .shop-slider {               

                &__title {

                    flex: 0 0 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    height: px-to-vw(30px, 1260px);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;

                    &-text {
                        color: white;
                        text-align: center;
                        font-family: 'Circe';
                        font-size: px-to-vw(20px, 1260px);
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: uppercase;
                    }

                    &-prev,
                    &-next {

                        height: 100%;
                        width: px-to-vw(50px, 1260px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        transition: background-color 0.25s linear;
                        cursor: pointer;

                        &:before {
                            content: '';
                            display: block;
                            width: px-to-vw(17px, 1260px);
                            height: px-to-vw(20px, 1260px);
                        }

                        &:hover {
                            background-color: $green-light;
                        }

                    }

                    &-prev {

                        border-right: $border-divider;
                        margin-right: auto;

                        &:before {
                            background: url('../img/sprite.svg#sprite-arrow-prev-view') no-repeat;
                            background-size: 100% 100%;
                        }

                        &:hover:before {
                            background: url('../img/sprite.svg#sprite-arrow-prev-hover-view') no-repeat;
                            background-size: 100% 100%;
                        }

                    }

                    &-next {
                        
                        border-left: $border-divider;
                        margin-left: auto;

                        &:before {
                            background: url('../img/sprite.svg#sprite-arrow-next-view') no-repeat;  
                            background-size: 100% 100%;   
                        }

                        &:hover:before {
                            background: url('../img/sprite.svg#sprite-arrow-next-hover-view') no-repeat;  
                            background-size: 100% 100%;
                        }

                    }

                }

            }


        }

        &-footer {   
            width: 100%;         
            height: px-to-vw(30px, 1260px);
        }

        &-content {

            width: 100%;
            height: px-to-vw(220px, 1260px);
            background-color: white;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: flex-start;
            flex: 1;
            background-color: $black;

            &.swiper-slide {
                flex: 0 0 100%;
            }

            & .shop-content {

                &__item {

                    display: flex;
                    position: relative;
                    background-color: white;
                    flex: 1;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: px-to-vw(12px, 1260px);

                    &.--shop-color-1 {
                        background-color: #E8E8E8;
                    }
    
                    &.--shop-color-2 {
                        background-color: #F4F4F4;
                    }
    
                    &-link {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 200;
                        transition: background-color .25s linear;
                    }
    
                    &-picture {
                        
                        display: flex;
                        flex: 1;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        padding-bottom: px-to-vw(5px, 1260px);
    
                        & picture,
                        & picture img {
                            display: block;
                            max-width: 100%;
                            margin: 0 auto;
                            transition: transform .25s linear;
                            mix-blend-mode: multiply;
                        }

                        &.--pic-1-1 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(194px, 1260px);
                            }
    
                        }
    
                        &.--pic-1-2 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(125px, 1260px);
                            }
    
                        }
    
                        &.--pic-1-3 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(132px, 1260px);
                            }
    
                        }
    
                        &.--pic-1-4 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(141px, 1260px);
                            }
    
                        }
    
                        &.--pic-1-5 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(188px, 1260px);
                            }
    
                        }

                        &.--pic-2-1 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(167px, 1260px);
                                mix-blend-mode: multiply;
                            }
    
                        }
    
                        &.--pic-2-2 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(114px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-3 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(91px, 1260px);
                            }
    
                        }
                        &.--pic-3-1 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(160px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-2 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(125px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-3 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(132px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-4 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(141px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-5 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(132px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-1 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(110px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-2 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(150px, 1260px);
                                max-width: px-to-vw(110px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-3 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(110px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-4 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(90px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-5 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(90px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-6 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(90px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-7 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(90px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-8 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(90px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-9 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(90px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-10 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(90px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-11,
                        &.--pic-2-1-12,
                        &.--pic-2-1-13,
                        &.--pic-2-1-14,
                        &.--pic-2-1-15,
                        &.--pic-2-1-16 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(90px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-17 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(110px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-18 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(150px, 1260px);
                                max-width: px-to-vw(110px, 1260px);
                            }
    
                        }
    
                        &.--pic-2-1-19 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(110px, 1260px);
                            }
    
                        }

                        &.--pic-3-1 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(130px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-2 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(125px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-3 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(132px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-4 {
    
                            & picture,
                            & picture img {
                                max-width: px-to-vw(141px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-5 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(110px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-6 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(110px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-7 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(120px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-8 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(110px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-9 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(110px, 1260px);
                            }
    
                        }
    
                        &.--pic-3-10 {
    
                            & picture,
                            & picture img {
                                max-height: px-to-vw(110px, 1260px);
                            }
    
                        }
    
                    }
    
                    &-content {
    
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: flex-start;

                        & .item-content {
    
                            &__text {

                                text-align: center;
        
                                flex: 1;
        
                                &-category {
                                    color: #1D1D1D;
                                    font-family: 'Circe';
                                    font-size: px-to-vw(12px, 1260px);
                                    font-style: normal;
                                    line-height: normal;
                                    font-weight: 300;
                                    margin-bottom: px-to-vw(5px, 1260px);
                                }
    
                                &-title {
                                    color: #1D1D1D;
                                    font-family: 'Circe';
                                    font-size: px-to-vw(16px, 1260px);
                                    font-style: normal;
                                    line-height: normal;
                                    font-weight: 600;
                                    text-transform: uppercase;
                                    height: px-to-vw(48px, 1260px);
                                    width: px-to-vw(190px, 1260px);
                                }
        
                            }


                        }
    
                    }

                }
                

                &__item.--horizontal {

                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 0;

                    & .shop-content {

                        &__item {

                            &-picture {
                                margin-right: px-to-vw(22px, 1260px);
                                display: block;
                                margin-bottom: 0;
                            }
    
                            &-content {
    
                                width: px-to-vw(204px, 1260px);
                                height: auto;
        
                                & .item-content {
            
                                    &__text {

                                        text-align: left;
    
                                        &-category {
                                        }
            
                                        &-title {
                                        }
                
                                    }
        
        
                                }
            
                            }

                        }

                    }

                }

                &__item:hover  {                    

                    & .shop-content {

                        &__item {

                            &-link {                                
                                background-color: rgba(0, 0, 0, 0.06);
                            }

                            &-picture img {
                                transform: scale(1.1);
                            }

                        }

                    }
    
                }

            }          

            /*&:hover  {

                & .column-item {

                    &__link {
                        background-color: rgba(0, 0, 0, 0.03);
                    }

                    &__picture {

                        & img {
                            transform: scale(1.1);
                        }

                    }

                }

            }*/

        }

    }

}

.fullscreen-banner {

    /*width: 100%;
    padding-top: 78.125%;*/
    width: 768px;
    padding-top: 600px;
    margin: 0 auto;
    background-color: $black;
    position: relative;
    overflow: hidden;

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
    }

    &__title {

        padding: 17px 22px 7px;
        border-bottom: $border-divider;
        color: #FFF;
        font-family: 'Circe';
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;

        &.--2 {
            padding: 17px 22px 9px;
            font-size: 41px;
        }

        &.--3 {
            padding: 17px 22px 12px;
            font-size: 38px;
        }

    }

    &__shop {
        
        background-color: $black;
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 500;
        display: flex;
        flex-direction: column;
        display: block;
        transition: top 0.5s ease-out;

        &.--open {
            top: 0;
        }

        &-close {            

            position: absolute;
            width: 30px;
            height: 30px;
            top: 10px;
            right: 10px;
            border-radius: 100%;
            background-color: rgba(0, 0, 0, 0.30);
            z-index: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:before {
                content: '';
                display: block;
                width: 18px;
                height: 18px;
                background: url('../img/sprite.svg#sprite-close-view') no-repeat;
                background-size: 100% 100%;
            }

            &:hover {

                background-color: $black;

                &:before {
                    background: url('../img/sprite.svg#sprite-close-hover-view') no-repeat;
                    background-size: 100% 100%;
                }

            }

        }        

        &-slider {

            height: 100%;

            & .shop-slider {          

                &__title {

                    flex: 0 0 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;

                    &-text {
                        color: white;
                        text-align: center;
                        font-family: 'Circe';
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: uppercase;
                    }

                    &-prev,
                    &-next {

                        height: 100%;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        transition: background-color 0.25s linear;
                        cursor: pointer;

                        &:before {
                            content: '';
                            display: block;
                            width: 17px;
                            height: 20px;
                        }

                        &:hover {
                            background-color: $green-light;
                        }

                    }

                    &-prev {

                        border-right: $border-divider;
                        margin-right: auto;

                        &:before {
                            background: url('../img/sprite.svg#sprite-arrow-prev-view') no-repeat;
                            background-size: 100% 100%;
                        }

                        &:hover:before {
                            background: url('../img/sprite.svg#sprite-arrow-prev-hover-view') no-repeat;
                            background-size: 100% 100%;
                        }

                    }

                    &-next {
                        
                        border-left: $border-divider;
                        margin-left: auto;

                        &:before {
                            background: url('../img/sprite.svg#sprite-arrow-next-view') no-repeat;  
                            background-size: 100% 100%;   
                        }

                        &:hover:before {
                            background: url('../img/sprite.svg#sprite-arrow-next-hover-view') no-repeat;  
                            background-size: 100% 100%;
                        }

                    }

                }

                &__list {

                    height: calc(100% - 30px);

                    &-item {

                    }

                }

            }


        }

        &-content {

            width: 100%;
            height: 100%;
            background-color: white;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: flex-start;
            flex: 1;
            background-color: $black;

            &.swiper-slide {
                flex: 0 0 100%;
            }

            /*&.--1 {

                & .shop-content {

                    &__column {

                        &:nth-child(1),
                        &:nth-child(3) {
                            width: px-to-vw(531px);
                        }

                    }

                }

            }

            &.--2 {

                & .shop-content {
                    
                    &__column {

                        &:nth-child(1),
                        &:nth-child(3) {
                            width: px-to-vw(640px);
                        }

                    }

                }

            }

            &.--3 {

                & .shop-content {
                    
                    &__column {

                        flex:

                    }

                }

            }*/

            & .shop-content {

                &__column {
                    display: flex;
                    width: 100%;

                }

                &__column-item {

                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    padding: 20px;
                    position: relative;
                    background-color: white;

                    &.--shop-color-1 {
                        background-color: #E8E8E8;
                    }

                    &.--shop-color-2 {
                        background-color: #F4F4F4;
                    }

                    & .column-item {

                        &__link {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 200;
                            transition: background-color .25s linear;
                        }

                        &__picture {

                            display: flex;
                            flex: 1;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            padding-bottom: 5px;

                            & picture,
                            & picture img {
                                display: block;
                                max-width: 100%;
                                margin: 0 auto;
                                transition: transform .25s linear;
                                mix-blend-mode: darken;
                            }

                            &.--pic-1-1 {

                                & picture,
                                & picture img {
                                    max-width: 194px;
                                }

                            }

                            &.--pic-1-2 {

                                & picture,
                                & picture img {
                                    max-width: 132px;
                                }

                            }

                            &.--pic-1-3 {

                                & picture,
                                & picture img {
                                    max-width: 125px;
                                }

                            }

                            &.--pic-1-4 {

                                & picture,
                                & picture img {
                                    max-width: 141px;
                                }

                            }

                            &.--pic-1-5 {

                                & picture,
                                & picture img {
                                    max-width: 188px;
                                }

                            }

                            &.--pic-2-1 {

                                & picture,
                                & picture img {
                                    max-width: 297px;
                                }

                            }

                            &.--pic-2-2 {

                                & picture,
                                & picture img {
                                    max-width: 171px;
                                }

                            }

                            &.--pic-2-3 {

                                & picture,
                                & picture img {
                                    max-width: 141px;
                                }

                            }

                            &.--pic-3-1 {

                                & picture,
                                & picture img {
                                    max-width: 160px;
                                }

                            }

                            &.--pic-3-2 {

                                & picture,
                                & picture img {
                                    max-width: 125px;
                                }

                            }

                            &.--pic-3-3 {

                                & picture,
                                & picture img {
                                    max-width: 132px;
                                }

                            }

                            &.--pic-3-4 {

                                & picture,
                                & picture img {
                                    max-width: 132px;
                                }

                            }

                            &.--pic-3-5 {

                                & picture,
                                & picture img {
                                    max-width: 132px;
                                }

                            }

                            &.--pic-2-1-1 {

                                & picture,
                                & picture img {
                                    max-width: 197px;
                                }

                            }

                            &.--pic-2-1-2 {

                                & picture,
                                & picture img {
                                    max-width: 121px;
                                }

                            }

                            &.--pic-2-1-3 {

                                & picture,
                                & picture img {
                                    max-width: 197px;
                                }

                            }

                            &.--pic-2-1-4 {

                                & picture,
                                & picture img {
                                    max-width: 50px;
                                }

                            }

                            &.--pic-2-1-5 {

                                & picture,
                                & picture img {
                                    max-width: 80px;
                                }

                            }

                            &.--pic-2-1-6 {

                                & picture,
                                & picture img {
                                    max-width: 100px;
                                }

                            }

                            &.--pic-2-1-7 {

                                & picture,
                                & picture img {
                                    max-width: 80px;
                                }

                            }

                            &.--pic-2-1-8 {

                                & picture,
                                & picture img {
                                    max-width: 80px;
                                }

                            }

                            &.--pic-2-1-9 {

                                & picture,
                                & picture img {
                                    max-width: 80px;
                                }

                            }

                            &.--pic-2-1-10 {

                                & picture,
                                & picture img {
                                    max-width: 80px;
                                }

                            }

                            &.--pic-2-2-11 {

                                & picture,
                                & picture img {
                                    max-width: 80px;
                                }

                            }

                            &.--pic-2-2-12 {

                                & picture,
                                & picture img {
                                    max-width: 45px;
                                }

                            }

                            &.--pic-2-2-13 {

                                & picture,
                                & picture img {
                                    max-width: 80px;
                                }

                            }

                            &.--pic-2-2-14 {

                                & picture,
                                & picture img {
                                    max-width: 80px;
                                }

                            }

                            &.--pic-2-2-15 {

                                & picture,
                                & picture img {
                                    max-width: 80px;
                                }

                            }

                            &.--pic-2-2-16 {

                                & picture,
                                & picture img {
                                    max-width: 80px;
                                }

                            }

                            &.--pic-2-2-17 {

                                & picture,
                                & picture img {
                                    max-width: 210px;
                                }

                            }

                            &.--pic-2-2-18 {

                                & picture,
                                & picture img {
                                    max-width: 84px;
                                }

                            }

                            &.--pic-2-2-19 {

                                & picture,
                                & picture img {
                                    max-width: 210px;
                                }

                            }

                            &.--pic-3-1 {

                                & picture,
                                & picture img {
                                    /*max-width: 210px;*/
                                }

                            }

                            &.--pic-3-2 {

                                & picture,
                                & picture img {
                                    /*max-width: 210px;*/
                                }

                            }

                            &.--pic-3-3 {

                                & picture,
                                & picture img {
                                    /*max-width: 210px;*/
                                }

                            }

                            &.--pic-3-4 {

                                & picture,
                                & picture img {
                                    /*max-width: 210px;*/
                                }

                            }

                            &.--pic-3-5 {

                                & picture,
                                & picture img {
                                    max-width: 125px;
                                }

                            }

                            &.--pic-3-6 {

                                & picture,
                                & picture img {
                                    max-width: 125px;
                                }

                            }

                            &.--pic-3-7 {

                                & picture,
                                & picture img {
                                    max-width: 125px;
                                }

                            }

                            &.--pic-3-8 {

                                & picture,
                                & picture img {
                                    max-width: 125px;
                                }

                            }

                            &.--pic-3-9 {

                                & picture,
                                & picture img {
                                    max-width: 125px;
                                }

                            }

                            &.--pic-3-10 {

                                & picture,
                                & picture img {
                                    max-width: 125px;
                                }

                            }

                        }

                        &__content {

                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: flex-start;
                            height: 67px;
                            width: 190px;
                            margin: 0 auto;

                            &.--3-lines {
                                height: 90px;
                            }                            

                            &.--200 {
                                width: 200px;
                            }

                            &-name {

                                flex: 1;

                                & .content-name {

                                    &__category {
                                        color: #1D1D1D;
                                        font-family: 'Circe';
                                        font-size: 12px;
                                        font-style: normal;
                                        line-height: normal;
                                        font-weight: 300;
                                        margin-bottom: 5px;
                                        text-align: center;
                                    }

                                    &__title {
                                        color: #1D1D1D;
                                        font-family: 'Circe';
                                        font-size: 16px;
                                        font-style: normal;
                                        line-height: normal;
                                        font-weight: 600;
                                        text-transform: uppercase;
                                        text-align: center;
                                    }

                                }

                            }

                        }

                    }

                    &:hover  {

                        & .column-item {

                            &__link {
                                background-color: rgba(0, 0, 0, 0.03);
                            }

                            &__picture {

                                & img {
                                    transform: scale(1.1);
                                }

                            }

                        }

                    }

                }

            }

        }

    }

    &__list {

        &-item {

            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: flex-start;
            overflow: hidden;

            & .list-item {

                &__background {
                    position: absolute;
                    bottom: 0;
                    right: 18px;
                    z-index: 100;
                    width: 518px;
                    height: 502px;
                    background: url('../img/sprite.svg#sprite-lio-logo-bg-view') no-repeat;
                    background-size: 100% 100%;
                }

                &__left {

                    width: 343px;
                    border-right: $border-divider;
                    margin-right: 40px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    z-index: 130;

                    &.--2 {
                        margin-right: 0;
                        border-right: none;
                    }

                }

                &__right {
                    border-left: $border-divider;
                    flex: 1;
                    z-index: 110;
                    position: relative;
                }

                &__tags {

                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 150;
                    opacity: 0;
                    transition: opacity 0.5s linear;

                    &:hover {
                        opacity: 1;
                    }

                    &-item {

                        width: 47px;
                        height: 47px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 100;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        & span.idle {
                            display: block;
                            width: 100%;
                            height: 100%;
                            background: url('../img/sprite.svg#sprite-tag-view') no-repeat;
                            background-size: 100% 100%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 102;
                            opacity: 1;
                            transition: opacity 0.5s ease-in;
                            cursor: pointer;
                        }

                        & span.active {
                            display: block;
                            width: 200%;
                            height: 200%;
                            background: url('../img/sprite.svg#sprite-tag-hover-view') no-repeat;
                            background-size: 100% 100%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transform-origin: center center;
                            z-index: 100;
                            opacity: 0;
                            transition: width 0.5s ease-in, height 0.5s ease-in, opacity 0.5s ease-in;

                        }

                        & span.idle:hover,
                        & span.idle:active {

                            opacity: 0;

                            & + span.active {
                                opacity: 1;
                                width: 100%;
                                height: 100%;
                            }

                        }

                        &.--1 {                                                      
                            top: 145px;
                            left: 88px;
                        }

                        &.--2 {                                                      
                            top: 358px;
                            left: 296px;
                        }

                        &.--3 {                                                      
                            top: 426px;
                            left: 136px;
                        }

                        &.--4 {                                                      
                            top: 184px;
                            left: 190px;
                        }

                        &.--5 {                                                      
                            top: 243px;
                            left: 32px;
                        }

                        &.--6 {                                                      
                            top: 406px;
                            left: 67px;
                        }
                        
                        &.--7 {                                                      
                            top: 92px;
                            left: 155px;
                        }
                        
                        &.--8 {                                                       
                            top: 196px;
                            left: 39px;
                        }
                        
                        &.--9 {                                                       
                            top: 243px;
                            left: 231px;
                        }
                        
                        &.--10 {                                                       
                            top: 287px;
                            left: 92px;
                        }
                        
                        &.--11 {                                                       
                            top: 427px;
                            left: 169px;
                        }
                        
                        &.--12 {                                                       
                            top: 70px;
                            left: 129px;
                        }
                        
                        &.--13 {                                                       
                            top: 158px;
                            left: 228px;
                        }
                        
                        &.--14 {                                                       
                            top: 216px;
                            left: 316px;
                        }
                        
                        &.--15 {                                                       
                            top: 243px;
                            left: 68px;
                        }
                        
                        &.--16 {                                                       
                            top: 303px;
                            left: 127px;
                        }
                        
                        &.--17 {                                                       
                            top: 400px;
                            left: 277px;
                        
                        }
                        
                        &.--18 {                                                       
                            top: 427px;
                            left: 87px;
                        }
                        
                        &.--19 {                                                       
                            top: 174px;
                            left: 22px;
                        }
                        
                        &.--20 {                                                       
                            top: 174px;
                            left: 106px;
                        }
                        
                        &.--21 {                                                       
                            top: 258px;
                            left: 315px;
                        }
                        
                        &.--22 {                                                       
                            top: 284px;
                            left: 183px;
                        }
                        
                        &.--23 {                                                       
                            top: 407px;
                            left: 214px;
                        }
                        
                        &.--24 {                                                       
                            top: 71px;
                            left: 175px;
                        }
                        
                        &.--25 {                                                       
                            top: 224px;
                            left: 241px;
                        }
                        
                        &.--26 {                                                       
                            top: 301px;
                            left: 170px;
                        }
                        
                        &.--27 {                                                       
                            top: 54px;
                            left: 186px;
                        }
                        
                        &.--28 {                                                       
                            top: 135px;
                            left: 183px;
                        }
                        
                        &.--29 {                                                       
                            top: 184px;
                            left: 131px;
                        }
                        
                        &.--30 {                                                       
                            top: 359px;
                            left: 105px;
                        }
                        
                        &.--31 {                                                       
                            top: 332px;
                            left: 255px;
                        }
                        
                    }

                }           

                &__about {

                    position: absolute;
                    top: 0;
                    left: -100%;
                    z-index: 400;
                    background-color: $black;
                    width: 100%;
                    height: 100%;
                    padding: 57px 22px 20px;
                    transition: left 0.5s ease-out;

                    &.--open {
                        left: 0;
                    }

                    &-close {

                        position: absolute;
                        width: 38px;
                        height: 38px;
                        top: 15px;
                        right: 15px;
                        z-index: 100;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:before {
                            content: '';
                            display: block;
                            width: 26px;
                            height: 26px;
                            background: url('../img/sprite.svg#sprite-close-view') no-repeat;
                            background-size: 100% 100%;

                        }

                        &:hover {
                            &:before {
                                background: url('../img/sprite.svg#sprite-close-hover-view') no-repeat;
                                background-size: 100% 100%;
                            }
                        }

                    }

                    &-heading {
                        width: 100%;
                        color: white;
                        font-family: 'Circe';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }

                    &-divider {
                        
                        width: 100%;
                        background-color: white;
                        height: 1px;
                        margin: 50px 0 20px 0;

                        &.--2 {
                            margin: 20px 0 10px 0;
                        }

                    }

                    &-text {
                        width: 100%;
                        color: white;
                        font-family: 'Circe';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        margin-bottom: 20px;
                    }

                    &-footing {
                        color: #6C6C6C;
                        font-family: 'Circe';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }


                }
                
                &__footer {

                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 120;
                    height: 46px;
                    width: 100%;
                    border-top: $border-divider;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;                    

                    &-onexclusive {

                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        margin-left: auto;
                        text-decoration: none;
                        padding: 0 15px 0 15px;
                        color: white;
                        border-left: $border-divider;
                        position: relative;

                        &:before {
                            content: '';
                            display: block;
                            width: 53.25px;
                            height: 46px;
                            background: url('../img/sprite.svg#sprite-hash-view') no-repeat;
                            margin-right: 8.5px;
                            background-size: 100% 100%;
                        }

                        & .footer-onexclusive {

                            &__container {
                                position: relative;
                                top: 1px;
                            }

                            &__link {
                                display: block;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                z-index: 100;
                            }

                            &__contest {
                                font-family: 'Circe';
                                font-size: 12.105px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.242px;
                            }

                            &__text {
                                text-align: center;
                                font-family: 'Circe';
                                font-size: 17px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 110%;
                                text-transform: uppercase;
                            }

                        }

                        &:hover {

                            color: $black;
                            background: $green-light;

                            &:before {
                                background: url('../img/sprite.svg#sprite-hash-hover-view') no-repeat;
                                background-size: 100% 100%;
                            }

                        }

                    }

                }

                &__shop {

                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    bottom: 6px;
                    left: 348px;
                    z-index: 140;
                    cursor: pointer;                     

                    &:before {
                        content: '';
                        display: block;
                        width: 30px;
                        height: 35.3px;
                        background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                        background-size: 100% 100%;
                    }

                }

                &__picture {

                    & picture,
                    & picture img {
                        display: block;
                        /*height: 100%;*/
                        width: 384px;
                        height: 533px;
                    }

                    &.--2 {

                        & picture,
                        & picture img {
                            width: 100%;
                            height: auto;
                        }

                        position: relative;

                    }

                    &-line {
                        width: 40px;
                        height: 100px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 100;
                        border-right: $border-divider;
                    }

                }

                &__rail {

                    flex: 1;
                    width: 100%;
                    position: relative;

                    & picture,
                    & picture img {
                        display: block;
                        height: 100%;
                        max-width: 100%;
                    }

                    &-picture {

                        display: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 110;

                        & picture,
                        & picture img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            max-width: 100%;
                        }

                    }

                    &-adv {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        max-height: 100%;
                        width: 75%;
                        color: #fff;
                        font-family: "Circe";
                        font-size: 6px;
                        font-weight: 300;
                        line-height: 122.727%;
                        padding: 5px;
                        background-color: rgba(9, 9, 9, 0.5);
                        z-index: 200;
                    }

                    &:hover .list-item__rail-picture.--active {
                        display: block;
                    }

                    & .list-item__rail-picture.--hover,
                    &:hover .list-item__rail-picture.--active.--hover {
                        display: block;
                        z-index: 115;
                    }

                    &-nav {

                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 120;

                        & .rail-nav {

                            &__item {

                                display: block;
                                position: absolute;
                                height: 100%;
                                z-index: 120;
                                top: 0px;
                                left: 0px;
                                cursor: pointer;

                                &.--1 {
                                    width: 133px;
                                    height: 310px;
                                    z-index: 130;
                                }

                                &.--2 {
                                    width: 155px;
                                    left: 78px;
                                    top: 41px;
                                    height: 311px;
                                }

                                &.--3 {
                                    width: 99px;
                                    left: 232px;
                                    top: 20px;
                                    z-index: 130;
                                    height: 332px;
                                }

                                &.--4 {
                                    left: 15px;
                                    top: 58px;
                                    width: 133px;
                                    height: 253px;
                                    z-index: 130;
                                }

                                &.--5 {
                                    width: 195px;
                                    left: 147px;
                                    top: 31px;
                                    height: 296px;
                                }

                            }

                        }

                    }

                }

                &__content {                    

                    &-text {
                        color: white;
                        font-family: 'Circe';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        padding: 12px 9px 22px 22px;
                        line-height: 108%;
                    }

                    &-buttons {

                        height: 41px;
                        border-top: $border-divider;
                        margin-top: auto;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: stretch;
                        justify-content: flex-start;
                        
                        & .content-buttons {

                            &__prev,
                            &__next {
    
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;
                                width: 58px;
                                border-right: $border-divider;
                                transition: background-color 0.25s linear;
                                cursor: pointer;
    
                                &:before {
                                    content: '';
                                    display: block;
                                    width: 14.81px;
                                    height: 17.67px;
                                }
    
                                &:hover {
                                    background-color: $green-light;
                                }
    
                            }
    
                            &__prev {                        
                                width: 60px;
                            }
    
                            &__prev:before {
                                background: url('../img/sprite.svg#sprite-arrow-prev-view') no-repeat;
                                background-size: 100% 100%;
                            }
    
                            &__next:before {
                                background: url('../img/sprite.svg#sprite-arrow-next-view') no-repeat; 
                                background-size: 100% 100%;                               
                            }
    
                            &__prev:hover:before {
                                background: url('../img/sprite.svg#sprite-arrow-prev-hover-view') no-repeat;
                                background-size: 100% 100%;
                            }
    
                            &__next:hover:before {
                                background: url('../img/sprite.svg#sprite-arrow-next-hover-view') no-repeat;  
                                background-size: 100% 100%;
                            }
    
                            &__more {
    
                                flex: 1;
                                text-align: center;
                                color: white;
                                font-family: 'Circe';
                                font-size: 16px;
                                font-weight: 600;
                                line-height: normal;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: background-color 0.25s linear, color 0.25s linear;
    
                                &:hover {
                                    background-color: $green-light;
                                    color: $black;
                                }
    
                            }

                        }

                    }

                }

            }

        }

    }

}

.feature-banner-mobile,
.billboard-banner-mobile,
.fullscreen-banner-mobile {
    display: none;
}

@media screen and (max-width: 767px) {

    .fullscreen-banner {

        width: 100%;

        &__title {
            padding: 20px 22px 10px;
            font-family: 'Circe';
            font-size: 38px;
        }
    
        &__list {
    
            &-item {
                flex-wrap: nowrap;

                & .list-item {
    
                    &__left {
                        width: 343px;
                        flex: 0 0 343px;
                    }
    
                    &__right {
                        flex: 0;
                    } 

                }

            }

        }

    }

}

@media screen and (max-width: 650px) {

    $border-divider: solid px-to-vw(1px) rgba(255, 255, 255, 0.5);

    .feature-banner,
    .billboard-banner,
    .fullscreen-banner {
        display: none;
    }

    .feature-banner-mobile,
    .billboard-banner-mobile,
    .fullscreen-banner-mobile {

        &__viewport {

            overflow-x: scroll;
            overflow-y: hidden;
            height: 100%;

            &.--shop {

                overflow-x: visible;
                position: absolute;
                bottom: -390px;
                left: 0;
                height: 390px;
                width: 100%;
                z-index: 500;
                transition: bottom 0.5s ease-out;

                &.--open {
                    bottom: 0;
                }

            }

            &.--about {

                overflow-x: visible;
                position: absolute;
                bottom: -390px;
                left: 0;
                height: 390px;
                width: 100%;
                z-index: 500;
                transition: bottom 0.5s ease-out;
                background-color: $black;
                padding: 65px 10px 0px 40px;
                border-radius: 15px 15px 0px 0px;
                transition: bottom 0.5s ease-out;

                &.--open {
                    bottom: 0;
                }

            }

            &-container {
                display: flex;
                flex-wrap: nowrap;
                height: 100%;
            }

            &.--shop .feature-banner-mobile__viewport-container {
            }

        }

        &__close {

            position: absolute;
            width: 24px;
            height: 24px;
            top: 6px;
            right: 12px;
            background: url('../img/sprite.svg#sprite-close-mobile-view') no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            z-index: 150;

            &.--about {
                top: 20px;
                right: 20px;
                background: url('../img/sprite.svg#sprite-close-mobile-white-view') no-repeat;
                background-size: 100% 100%;
            }

        }

        &__close-swipe {
            position: absolute;
            width: 49px;
            height: 12px;
            top: 6px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background-color: #1D1D1D;
                border-radius: 2.5px;
            }

            &.--about {

                &:before {
                    background-color: white;
                }

            }

        }

        &__about {

            overflow-y: scroll;

            &-container {
                padding-bottom: 60px;
                padding-right: 40px;
            }

            &-heading {

                color: white;
                font-family: 'Circe';
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                & span {
                    text-decoration: underline;
                }

            }

            &-divider {
                margin: 40px 0;
                height: 1px;
                background-color: white;
            }

            &-text {

                margin-bottom: 15px;

                & p {

                    color: white;
                    font-family: 'Circe';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 125%;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                }
                
            }

            &-footing {
                color: #6C6C6C;
                font-family: 'Circe';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
            }

        }

        &__shop {

            height: 100%;
            overflow-x: scroll;

            &-container {
                height: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: stretch;
                justify-content: flex-start;
            }

            &-item {

                position: relative;
                display: flex;
                flex-direction: column;
                padding: 25px;
                height: 100%;
                flex: 0 0 287px;
                width: 287px;
                background-color: white;

                &:first-child {
                    border-top-left-radius: 15px;
                }

                &:last-child {
                    border-top-right-radius: 15px;
                }

                &.--color-1 {
                    background-color: #F4F4F4;
                }

                &.--color-2 {
                    background-color: #E8E8E8;
                }

                & .shop-item {

                    &__link {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 100;
                    }

                    &__picture {
                        
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 30px;

                        & picture,
                        & picture img {
                            display: block;
                            max-width: 100%;
                            max-height: 100%;
                            max-height: 236px;
                        }

                    }

                    &__content {

                        margin-top: auto;

                        &-category {
                            color: #1D1D1D;
                            text-align: center;
                            font-family: 'Circe';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            margin-bottom: 8px;
                        }

                        &-title {
                            color: #1D1D1D;
                            text-align: center;
                            font-family: 'Circe';
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 100%;
                            letter-spacing: -0.22px;
                            text-transform: uppercase;
                        }
                        
                    }

                }

            }

        }

    }

    .feature-banner-mobile {

        display: block;
        width: 100%;
        height: 420px;
        overflow: hidden;
        position: relative;

        &__viewport {

            overflow-x: scroll;
            overflow-y: hidden;
            height: 100%;

            &.--shop {

                overflow-x: visible;
                position: absolute;
                bottom: -390px;
                left: 0;
                height: 390px;
                width: 100%;
                z-index: 500;
                transition: bottom 0.5s ease-out;

                &.--open {
                    bottom: 0;
                }

            }

            &.--about {

                overflow-x: visible;
                position: absolute;
                bottom: -390px;
                left: 0;
                height: 390px;
                width: 100%;
                z-index: 500;
                transition: bottom 0.5s ease-out;
                background-color: $black;
                padding: 65px 10px 0px 40px;
                border-radius: 15px 15px 0px 0px;
                transition: bottom 0.5s ease-out;

                &.--open {
                    bottom: 0;
                }

            }

            &-container {
                display: flex;
                flex-wrap: nowrap;
                height: 100%;
            }

            &.--shop .feature-banner-mobile__viewport-container {
            }

        }

        &__close {

            position: absolute;
            width: 24px;
            height: 24px;
            top: 6px;
            right: 12px;
            background: url('../img/sprite.svg#sprite-close-mobile-view') no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            z-index: 150;

            &.--about {
                top: 20px;
                right: 20px;
                background: url('../img/sprite.svg#sprite-close-mobile-white-view') no-repeat;
                background-size: 100% 100%;
            }

        }

        &__close-swipe {
            position: absolute;
            width: 49px;
            height: 12px;
            top: 6px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background-color: #1D1D1D;
                border-radius: 2.5px;
            }

            &.--about {

                &:before {
                    background-color: white;
                }

            }

        }

        &__about {

            overflow-y: scroll;

            &-container {
                padding-bottom: 60px;
                padding-right: 40px;
            }

            &-heading {

                color: white;
                font-family: 'Circe';
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                & span {
                    text-decoration: underline;
                }

            }

            &-divider {
                margin: 40px 0;
                height: 1px;
                background-color: white;
            }

            &-text {

                margin-bottom: 15px;

                & p {

                    color: white;
                    font-family: 'Circe';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 125%;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                }
                
            }

            &-footing {
                color: #6C6C6C;
                font-family: 'Circe';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;
            }

        }

        &__shop {

            height: 100%;
            overflow-x: scroll;

            &-container {
                height: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: stretch;
                justify-content: flex-start;
            }

            &-item {

                position: relative;
                display: flex;
                flex-direction: column;
                padding: 25px;
                height: 100%;
                flex: 0 0 287px;
                width: 287px;
                background-color: white;

                &:first-child {
                    border-top-left-radius: 15px;
                }

                &:last-child {
                    border-top-right-radius: 15px;
                }

                &.--color-1 {
                    background-color: #F4F4F4;
                }

                &.--color-2 {
                    background-color: #E8E8E8;
                }

                & .shop-item {

                    &__link {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 100;
                    }

                    &__picture {
                        
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 30px;

                        & picture,
                        & picture img {
                            display: block;
                            max-width: 100%;
                            max-height: 100%;
                            max-height: 236px;
                        }

                    }

                    &__content {

                        margin-top: auto;

                        &-category {
                            color: #1D1D1D;
                            text-align: center;
                            font-family: 'Circe';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            margin-bottom: 8px;
                        }

                        &-title {
                            color: #1D1D1D;
                            text-align: center;
                            font-family: 'Circe';
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 100%;
                            letter-spacing: -0.22px;
                            text-transform: uppercase;
                        }
                        
                    }

                }

            }

        }

        &__list {

            display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
            justify-content: flex-start;
            height: 100%;
            background: $black;

            &-item {

                width: 650px;

                &:nth-child(1) {

                    display: flex;
                    flex-wrap: nowrap;
                    align-items: stretch;
                    justify-content: flex-start;

                    & .list-item {

                        &__left {
                            width: 405px;
                            position: relative;
                            border-right: $border-divider;
                        }

                        &__right {
                            width: 245px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            border-right: $border-divider;
                        }

                        &__picture {

                            width: 100%;
                            height: 100%;

                            & picture,
                            & picture img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }

                        }

                        &__look {

                            flex: 1;
                            width: 100%;

                            &:first-child {
                                border-bottom: $border-divider;
                            }

                            & picture,
                            & picture img {
                                display: block;
                                object-fit: cover;
                                max-width: 100%;
                                max-height: 100%;
                            }

                        }

                        &__content {

                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            width: 100%;
                            padding: 20px 26px 0 16px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 100;

                            &-tags {

                                flex: 1;
                                width: 100%;
                                border-top: $border-divider;
                                border-right: $border-divider;
                                position: relative;
                                opacity: 1;
                                transition: opacity 0.5s linear;

                                &.--open {
                                    opacity: 1;
                                }

                                & .content-tags {
                
                                    &__item {
                
                                        width: 25px;
                                        height: 25px;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        z-index: 100;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;
                
                                        & span.idle {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            background: url('../img/sprite.svg#sprite-tag-view') no-repeat;
                                            background-size: 100% 100%;
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, -50%);
                                            z-index: 102;
                                            opacity: 1;
                                            transition: opacity 0.5s ease-in;
                                            cursor: pointer;
                                        }
                
                                        & span.active {
                                            display: block;
                                            width: 200%;
                                            height: 200%;
                                            background: url('../img/sprite.svg#sprite-tag-hover-view') no-repeat;
                                            background-size: 100% 100%;
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, -50%);
                                            transform-origin: center center;
                                            z-index: 100;
                                            opacity: 0;
                                            transition: width 0.5s ease-in, height 0.5s ease-in, opacity 0.5s ease-in;
                
                                        }
                
                                        & span.idle:active,
                                        & span.idle:hover {
                
                                            opacity: 0;
                
                                            & + span.active {
                                                opacity: 1;
                                                width: 100%;
                                                height: 100%;
                                            }
                
                                            & + span.active + span.text {
                                                opacity: 1;
                                            }
                
                                        }
                
                                        &.--1 {                                                                      
                                            top: 84px;
                                            left: 218px;               
                                        }
                
                                        &.--2 {   
                                            top: 116px;
                                            left: 98px;            
                                        }
                
                                        &.--3 {     
                                            top: 239px;
                                            left: 148px;          
                                        }
                
                                        &.--4 {     
                                            top: 34px;
                                            left: 96px;        
                                        }
                
                                        &.--5 {     
                                            top: 106px;
                                            left: 248px;        
                                        }
                
                                        &.--6 {     
                                            top: 126px;
                                            left: 179px;         
                                        }
                
                                        &.--7 {     
                                            top: 184px;
                                            left: 58px;        
                                        }
                
                                        &.--8 {     
                                            top: 230px;
                                            left: 98px;         
                                        }
                
                                        &.--9 {     
                                            top: 296px;
                                            left: 224px;        
                                        }
                
                                        &.--10 {     
                                            top: 314px;
                                            left: 79px;         
                                        }
                
                                        &.--11 {     
                                            top: 43px;
                                            left: 161px;        
                                        }
                
                                        &.--12 {     
                                            top: 181px;
                                            left: 208px;       
                                        }
                
                                        &.--13 {     
                                            top: 280px;
                                            left: 163px;        
                                        }
                                        
                                    }
                
                                }

                            }

                            &-buttons {

                                height: 59px;
                                border-top: $border-divider;
                                border-right: $border-divider;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: stretch;
                                justify-content: flex-start;
                                margin-top: auto;
                                position: relative;

                                & .content-buttons {
                                    
                                    &__shop {

                                        position: absolute;
                                        top: -50px;
                                        left: 0;
                                        z-index: 100;

                                        &-icon {

                                            width: 35px;
                                            height: 35px;
                                            display: flex;
                                            flex-wrap: wrap;
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 100%;
                                            background-color: rgba(0, 0, 0, 0.5);
                                            position: relative;
                                            /*top: 50%;
                                            transform: translateY(-50%);
                                            top: -50px;*/

                                            &:before {
                                                content: '';
                                                display: block;
                                                width: 15px;
                                                height: 17px;
                                                background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                                                background-size: 100% 100%;
                                            }

                                        }

                                    }

                                    &__adv {
                                        flex: 1;
                                        border-right: $border-divider;
                                        color: #fff;
                                        font-family: "Circe";
                                        font-size: 9px;
                                        font-weight: 300;
                                        line-height: 122.727%;
                                        padding: 1.3020833333vw 1.5625vw;
                                        background-color: rgba(9,9,9,.5);
                                        z-index: 200;
                                    }
                                    
                                    &__read {

                                        color: white;
                                        text-align: center;
                                        font-family: 'Circe';
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 125%;
                                        padding: 19px 27px;
                                        background: rgba(9, 9, 9, 0.50);
                                        transition: all 0.25s linear;

                                        &:hover {
                                            background-color: $green-light;
                                            color: $black;
                                        }

                                    }

                                }

                            }

                        }

                    }

                }

                &:nth-child(2) {

                    width: 691px;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: stretch;
                    justify-content: flex-start;
                    padding-top: 20px;
                    padding-left: 19px;

                    & .list-item {

                        &__left {
                            width: 301px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                        }

                        &__right {
                            flex: 1;
                            position: relative;
                        }

                        &__logos {

                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: flex-start;
                            margin-bottom: 26px;

                            &-lio {
                                width: 62px;
                                height: 60px;
                                background: url('../img/sprite.svg#sprite-logo-view') no-repeat;
                                margin-right: 21px;
                                background-size: 100% 100%;
                            }

                            &-symbol {
                                width: 102.2px;
                                height: 28.5px;
                                background: url('../img/sprite.svg#sprite-symbol-logo-view') no-repeat;
                                background-size: 100% 100%;
                            }

                            & a {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }

                        }

                        &__title {
                            color: white;
                            font-family: 'Circe';
                            font-size: 25px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 108%;
                            margin-bottom: 15px;
                        }

                        &__text {
                            color: white;
                            font-family: 'Circe';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 125%;
                        }

                        &__buttons {

                            height: 59px;
                            width: 100%;
                            border-top: $border-divider;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: stretch;
                            justify-content: flex-start;
                            margin-top: auto;

                            &-arrows {

                                display: flex;
                                flex-wrap: wrap;
                                align-items: stretch;
                                justify-content: flex-start;

                                & .buttons-arrows {

                                    &__prev,
                                    &__next {

                                        display: flex;
                                        flex-wrap: wrap;
                                        border-right: $border-divider;

                                        &:before {
                                            content: '';
                                            display: block;
                                            width: 26px;
                                            height: 32px;
                                        }

                                    }

                                    &__prev {

                                        width: 51px;
                                        align-items: center;
                                        justify-content: flex-start;

                                        &:before {
                                            background: url('../img/sprite.svg#sprite-arrow-prev-mobile-view') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                        
                                    }

                                    &__next {
                                        
                                        width: 76px;
                                        align-items: center;
                                        justify-content: center;

                                        &:before {
                                            background: url('../img/sprite.svg#sprite-arrow-next-mobile-view') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                        
                                    }

                                }

                            }
                                    
                            &-read {
                                flex: 1;
                                color: white;
                                text-align: center;
                                font-family: 'Circe';
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 125%;
                                padding: 19px 27px;
                                background: rgba(9, 9, 9, 0.50);
                                transition: all 0.25s linear;
    
                                &:hover {
                                    background-color: $green-light;
                                    color: $black;
                                }
    
                            }

                        }

                        &__picture {                            

                            width: 100%;
                            height: 100%;
                            border: $border-divider;
                            border-bottom: none;

                            & picture,
                            & picture img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                            
                        }

                        &__tags {

                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: calc(100% - 59px);
                            z-index: 200;
                            opacity: 1;
                            transition: opacity 0.5s linear;

                            &.--open {
                                opacity: 1;
                            }
            
                            &-item {
        
                                width: 25px;
                                height: 25px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 100;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
        
                                & span.idle {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    background: url('../img/sprite.svg#sprite-tag-view') no-repeat;
                                    background-size: 100% 100%;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    z-index: 102;
                                    opacity: 1;
                                    transition: opacity 0.5s ease-in;
                                    cursor: pointer;
                                }
        
                                & span.active {
                                    display: block;
                                    width: 200%;
                                    height: 200%;
                                    background: url('../img/sprite.svg#sprite-tag-hover-view') no-repeat;
                                    background-size: 100% 100%;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    transform-origin: center center;
                                    z-index: 100;
                                    opacity: 0;
                                    transition: width 0.5s ease-in, height 0.5s ease-in, opacity 0.5s ease-in;
        
                                }
        
                                & span.idle:active,
                                & span.idle:hover {
        
                                    opacity: 0;
        
                                    & + span.active {
                                        opacity: 1;
                                        width: 100%;
                                        height: 100%;
                                    }
        
                                    & + span.active + span.text {
                                        opacity: 1;
                                    }
        
                                }
        
                                &.--1 {                                                                      
                                    top: 84px;
                                    left: 168px;               
                                }
        
                                &.--2 {   
                                    top: 173px;
                                    left: 83px;           
                                }
        
                                &.--3 {     
                                    top: 238px;
                                    left: 123px;        
                                }
        
                                &.--4 {     
                                    top: 305px;
                                    left: 162px;      
                                }

                                &.--5 {     
                                    top: 140px;
                                    left: 78px;     
                                }
        
                                &.--6 {     
                                    top: 194px;
                                    left: 237px;       
                                }
        
                                &.--7 {     
                                    top: 230px;
                                    left: 162px;       
                                }
        
                                &.--8 {     
                                    top: 300px;
                                    left: 197px;    
                                }
        
                                &.--9 {     
                                    top: 150px;
                                    left: 14px;       
                                }
                
                                &.--10 {     
                                    top: 55px;
                                    left: 157px;       
                                }
        
                                &.--11 {     
                                    top: 116px;
                                    left: 163px;       
                                }
        
                                &.--12 {     
                                    top: 144px;
                                    left: 116px;      
                                }
        
                                &.--13 {     
                                    top: 271px;
                                    left: 217px;       
                                }
        
                                &.--14 {     
                                    top: 280px;
                                    left: 104px;        
                                }
                                
                            }

                        }                        

                        &__shop {

                            height: 59px;
                            width: 100%;
                            border-top: $border-divider;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: stretch;
                            justify-content: flex-start;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            z-index: 110;
                            padding-left: 12px;

                            &-icon {

                                width: 35px;
                                height: 35px;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;
                                border-radius: 100%;
                                background-color: rgba(0, 0, 0, 0.5);
                                position: relative;
                                top: 50%;
                                transform: translateY(-50%);

                                &:before {
                                    content: '';
                                    display: block;
                                    width: 15px;
                                    height: 17px;
                                    background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                                    background-size: 100% 100%;
                                }

                            }

                            &-onexclusive {

                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;
                                margin-left: auto;
                                text-decoration: none;
                                padding: 0 15px 0 15px;
                                color: white;
                                border-left: $border-divider;
                                position: relative;

                                &:before {
                                    content: '';
                                    display: block;
                                    width: 47.5px;
                                    height: 41px;
                                    background: url('../img/sprite.svg#sprite-hash-view') no-repeat;
                                    margin-right: 13px;
                                    background-size: 100% 100%;
                                }

                                & .shop-onexclusive {

                                    &__container {
                                        position: relative;
                                        top: 2px;
                                    }
    
                                    &__link {
                                        display: block;
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        top: 0;
                                        left: 0;
                                        z-index: 100;
                                    }
    
                                    &__contest {
                                        font-family: 'Circe';
                                        font-size: 10px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 122.727%;
                                        letter-spacing: 0.2px;
                                    }
    
                                    &__text {
                                        text-align: center;
                                        font-family: 'Circe';
                                        font-size: 13.2px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: normal;
                                        text-transform: uppercase;
                                    }

                                }

                                &:hover,
                                &:active {
                                    color: $black;
                                    background: $green-light;

                                    &:before {
                                        background: url('../img/sprite.svg#sprite-hash-hover-view') no-repeat;
                                        background-size: 100% 100%;
                                    }

                                }

                            }

                        }

                    }

                    &.--2 {
                        
                        padding-top: 0;

                        & .list-item {

                            &__left {
                                padding-top: 20px;
                                width: 329px;
                            }

                            &__text {
                                padding-right: 70px;
                            }
                            
                        }

                    }

                }            

                &:nth-child(3) {

                    width: 609px;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: stretch;
                    justify-content: flex-start;
                    padding-top: 20px;
                    padding-left: 13px;
                    position: relative;

                    & .list-item {

                        &__left {
                            flex: 1;
                            position: relative;
                            padding-right: 13px;
                        }

                        &__right {

                            flex: 1;
                            position: relative;
                            border: $border-divider;
                            border-bottom: none;

                            &::before {
                                content: '';
                                display: block;
                                width: 105px;
                                height: 100%;
                                top: 0;
                                left: 0;
                                position: absolute;
                                z-index: 100;
                                background: linear-gradient(90deg, #0A0A0A 0%, rgba(10, 10, 10, 0.00) 100%);
                            }

                            /*&::after {
                                content: '';
                                display: block;
                                width: 1px;
                                height: 100%;
                                background: rgba(255, 255, 255, 0.5);
                                top: 0;
                                left: 0;
                                position: absolute;
                                z-index: 101;
                            }*/

                        }

                        &__tags {

                            position: absolute;
                            top: 20px;
                            left: 0;
                            width: 100%;
                            height: calc(100% - 59px);
                            z-index: 200;
                            opacity: 0;
                            transition: opacity 0.5s linear;

                            &.--open {
                                opacity: 1;
                            }
            
                            &-item {
        
                                width: 25px;
                                height: 25px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 100;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
        
                                & span.idle {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    background: url('../img/sprite.svg#sprite-tag-view') no-repeat;
                                    background-size: 100% 100%;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    z-index: 102;
                                    opacity: 1;
                                    transition: opacity 0.5s ease-in;
                                    cursor: pointer;
                                }
        
                                & span.active {
                                    display: block;
                                    width: 200%;
                                    height: 200%;
                                    background: url('../img/sprite.svg#sprite-tag-hover-view') no-repeat;
                                    background-size: 100% 100%;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    transform-origin: center center;
                                    z-index: 100;
                                    opacity: 0;
                                    transition: width 0.5s ease-in, height 0.5s ease-in, opacity 0.5s ease-in;
        
                                }
        
                                & span.idle:active,
                                & span.idle:hover {
        
                                    opacity: 0;
        
                                    & + span.active {
                                        opacity: 1;
                                        width: 100%;
                                        height: 100%;
                                    }
        
                                    & + span.active + span.text {
                                        opacity: 1;
                                    }
        
                                }
        
                                &.--1 {                                                                      
                                    top: 56px;
                                    left: 395px;              
                                }
        
                                &.--2 {   
                                    top: 73px;
                                    left: 251px;          
                                }
        
                                &.--3 {     
                                    top: 181px;
                                    left: 400px;      
                                }
        
                                &.--4 {     
                                    top: 194px;
                                    left: 493px;    
                                }
        
                                &.--5 {     
                                    top: 195px;
                                    left: 59px;    
                                }
        
                                &.--6 {     
                                    top: 316px;
                                    left: 399px;     
                                }
                                
                            }

                        }

                        &__rail {
                            
                            height: 100%;
                            position: relative;
                            z-index: 101;

                            & picture,
                            & picture img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }

                            &-buttons {
                                height: 59px;
                                width: 100%;
                                border-top: $border-divider;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                z-index: 100;
                            }

                        }                       

                        &__picture {

                            height: 100%;

                            & picture,
                            & picture img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }

                        }

                        &__content {

                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 110;

                            &-tags {
                                flex: 1;
                                width: 100%;
                                position: relative;
                            }

                            &-buttons {

                                height: 59px;
                                border-top: $border-divider;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: stretch;
                                justify-content: flex-start;
                                margin-top: auto;

                                & .content-buttons {
                                    
                                    &__shop {

                                        flex: 1;
                                        border-right: $border-divider;
                                        padding-left: 12px;

                                        &-icon {

                                            width: 35px;
                                            height: 35px;
                                            display: flex;
                                            flex-wrap: wrap;
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 100%;
                                            background-color: rgba(0, 0, 0, 0.5);
                                            position: relative;
                                            top: 50%;
                                            transform: translateY(-50%);

                                            &:before {
                                                content: '';
                                                display: block;
                                                width: 15px;
                                                height: 17px;
                                                background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                                                background-size: 100% 100%;
                                            }

                                        }

                                    }
                                    
                                    &__read {

                                        color: white;
                                        text-align: center;
                                        font-family: 'Circe';
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 125%;
                                        padding: 19px 27px;
                                        background: rgba(9, 9, 9, 0.50);
                                        transition: all 0.25s linear;

                                        &:hover {
                                            background-color: $green-light;
                                            color: $black;
                                        }

                                    }

                                }

                            }

                        }

                    }

                }

            }

        }

    }

    .billboard-banner-mobile {

        display: block;
        width: 100%;
        height: 250px;
        overflow: hidden;
        position: relative;

        &__viewport {

            &.--shop {
                bottom: -235px;
                height: 235px;
            }

            &.--about {
                bottom: -235px;
                height: 235px;
                padding: 55px 10px 0px 17px;
                border-radius: 7px 7px 0px 0px;
            }

        }

        &__close {

            &.--about {
                width: 16px;
                height: 16px;
                top: 16px;
                right: 16px;
                background: url('../img/sprite.svg#sprite-close-mobile-white-view') no-repeat;
                background-size: 100% 100%;
            }

        }

        &__about {

            overflow-y: scroll;

            &-container {
                padding-bottom: 18px;
                padding-right: 20px;
            }

            &-heading {
                font-size: 16px;
            }

            &-divider {
                margin: 23px 0;
            }

            &-text {

                margin-bottom: 8px;

                & p {

                    font-size: 16px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                }
                
            }

            &-footing {
                font-size: 16px;
            }

        }

        &__shop {

            &-item {

                padding: 25px 11px 15px 11px;
                flex: 0 0 216px;
                width: 216px;                

                &:first-child {
                    border-top-left-radius: 7px;
                }

                &:last-child {
                    border-top-right-radius: 7px;
                }

                & .shop-item {

                    &__picture {
                        
                        margin-bottom: 0;
                        max-height: 119px;

                        & picture,
                        & picture img {
                            height: 100%;
                        }

                    }

                    &__content {

                        &-title {
                            font-size: 16px;
                            letter-spacing: -0.16px;
                        }
                        
                    }

                }

            }

        }

        &__list {

            display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
            justify-content: flex-start;
            height: 100%;
            background: $black;

            &-item {

                display: flex;
                flex-wrap: nowrap;
                align-items: stretch;
                justify-content: flex-start;
                
                & .list-item {

                    &__tags {

                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 150;
                        opacity: 1;
                        transition: opacity 0.5s linear;

                        &.--open {
                            opacity: 1;
                        }

                        &-item {

                            width: 30px;
                            height: 30px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 100;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            & span.idle {
                                display: block;
                                width: 100%;
                                height: 100%;
                                background: url('../img/sprite.svg#sprite-tag-view') no-repeat;
                                background-size: 100% 100%;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                z-index: 102;
                                opacity: 1;
                                transition: opacity 0.5s ease-in;
                                cursor: pointer;
                            }

                            & span.active {
                                display: block;
                                width: 200%;
                                height: 200%;
                                background: url('../img/sprite.svg#sprite-tag-hover-view') no-repeat;
                                background-size: 100% 100%;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                transform-origin: center center;
                                z-index: 100;
                                opacity: 0;
                                transition: width 0.5s ease-in, height 0.5s ease-in, opacity 0.5s ease-in;

                            }

                            & span.idle:hover,
                            & span.idle:active {

                                opacity: 0;

                                & + span.active {
                                    opacity: 1;
                                    width: 100%;
                                    height: 100%;
                                }

                            }

                            &.--1 {                                                      
                                top: 25px;
                                left: 43px;
                            }

                            &.--2 {                                                      
                                top: 116px;
                                left: 134px;
                            }

                            &.--3 {                                                      
                                top: 154px;
                                left: 70px;
                            }

                            &.--4 {                                                      
                                top: 67px;
                                left: 110px;
                            }

                            &.--5 {                                                      
                                top: 113px;
                                left: 10px;
                            }

                            &.--6 {                                                      
                                top: 181px;
                                left: 54px;
                            }
                            
                            &.--7 {
                                top: 30px;
                                left: 94px;
                            }
                            
                            &.--8 {                                                       
                                top: 99px;
                                left: 27px;
                            }
                            
                            &.--9 {                                                       
                                top: 132px;
                                left: 145px;
                            }
                            
                            &.--10 {                                                       
                                top: 160px;
                                left: 67px;
                            }
                            
                            &.--11 {                                                       
                                top: 215px;
                                left: 108px;
                            }
                            
                            &.--12 {                                                       
                                top: 24px;
                                left: 61px;
                            }
                            
                            &.--13 {                                                       
                                top: 68px;
                                left: 101px;
                            }
                            
                            &.--14 {                                                       
                                top: 76px;
                                left: 151px;
                            }
                            
                            &.--15 {                                                       
                                top: 107px;
                                left: 16px;
                            }
                            
                            &.--16 {                                                       
                                top: 145px;
                                left: 55px;
                            }
                            
                            &.--17 {                                                       
                                top: 208px;
                                left: 34px;
                            }
                            
                            &.--18 {                                                       
                                top: 50px;
                                left: -3px;
                            }
                            
                            &.--19 {                                                       
                                top: 101px;
                                left: 5px;
                            }
                            
                            &.--20 {                                                       
                                top: 116px;
                                left: 153px;
                            }
                            
                            &.--21 {                                                       
                                top: 134px;
                                left: 98px;
                            }
                            
                            &.--22 {                                                       
                                top: 189px;
                                left: 103px;
                            }
                            
                            &.--23 {                                                       
                                top: 46px;
                                left: 77px;
                            }
                            
                            &.--24 {                                                       
                                top: 125px;
                                left: 43px;
                            }
                            
                            &.--25 {                                                       
                                top: 145px;
                                left: 108px;
                            }
                            
                            &.--26 {                                                       
                                top: 52px;
                                left: 80px;
                            }
                            
                            &.--27 {                                                       
                                top: 116px;
                                left: 85px;
                            }
                            
                            &.--28 {                                                       
                                top: 158px;
                                left: 42px;
                            }
                            
                            &.--29 {                                                       
                                top: 181px;
                                left: 112px;
                            }
                            
                        }

                    }

                }

                &:nth-child(1) {

                    & .list-item {

                        &__picture {

                            position: relative;

                            &-adv {
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                width: 127px;
                                color: #fff;
                                font-family: "Circe";
                                font-size: 4px;
                                font-weight: 300;
                                line-height: 122.727%;
                                padding: 2px 2px 0 2px;
                                background-color: rgba(9, 9, 9, 0.5);
                                z-index: 200;
                            }

                            &-shop {

                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;
                                position: absolute;
                                bottom: 7px;
                                left: 10px;
                                z-index: 160;                         

                                &:before {
                                    content: '';
                                    display: block;
                                    width: 18.119px;
                                    height: 21.408px;
                                    background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                                    background-size: 100% 100%;
                                }

                            }

                            & picture,
                            & picture img {
                                display: block;
                                height: 100%;
                            }

                        }

                        &__content {

                            position: relative;
                            border-right: $border-divider;
                            border-left: $border-divider;

                            &-title {

                                padding: 11px 15px 16px 129px;
                                border-bottom: $border-divider;
                                color: white;
                                font-family: 'Circe';
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 100%;
                                width: 396px;
                                position: relative;

                                &.--2 {
                                    width: 411px;                                    
                                }

                                & .content-title {

                                    &__logo {

                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        z-index: 110;
                                        width: 99px;
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        border-right: $border-divider;

                                        & a {
                                            display: block;
                                            width: 83px;
                                            height: 23px;
                                            background: url('../img/sprite.svg#sprite-symbol-logo-view') no-repeat;
                                            background-size: 100% 100%;
                                        }

                                    }

                                }

                            }

                            &-text {

                                padding: 8px 10px 0 129px;
                                color: white;
                                font-family: 'Circe';
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: 118%;
                                max-width: 396px;

                                &.--2 {
                                    max-width: 399px;
                                    padding-top: 20px;                                    
                                }
                                
                            }

                            &-read {

                                position: absolute;
                                bottom: 0;
                                left: 0;
                                z-index: 100;
                                width: 100px;
                                border-top: $border-divider;
                                border-right: $border-divider;
                                color: #FFF;
                                text-align: center;
                                font-family: 'Circe';
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                text-align: center;
                                transition: all 0.25s linear;
                                padding: 1px 0 1px;

                                &:hover,
                                &:active {
                                    background-color: $green-light;
                                    color: $black;
                                }

                            }

                        }

                        &__logo {

                            width: 40px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            padding-top: 23px;
                            border-right: $border-divider;

                            &-item {
                                display: block;
                                width: 28px;
                                height: 27px;
                                background: url('../img/sprite.svg#sprite-logo-view') no-repeat;
                                background-size: 100% 100%;
                            }

                            &-shop {

                                width: 100%;
                                height: 38px;
                                border-top: $border-divider;
                                margin-top: auto;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;

                                &:before {
                                    content: '';
                                    display: block;
                                    width: 18.119px;
                                    height: 21.408px;
                                    background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                                    background-size: 100% 100%;
                                }

                            }


                        }

                    }

                }

                &:nth-child(2) {

                    & .list-item {

                        &__picture {

                            position: relative;

                            & picture,
                            & picture img {
                                display: block;
                                height: 100%;
                            }

                        }

                        &__center {
                            border-right: $border-divider;
                            border-left: $border-divider;
                            width: 300px;
                            flex: 0 0 300px;
                            position: relative;
                        }

                        &__title {

                            height: 77px;
                            width: 100px;
                            border-bottom: $border-divider;

                            &.--2 {                                
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            &-logo {

                                width: 99px;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                & a {
                                    display: block;
                                    width: 83px;
                                    height: 23px;
                                    background: url('../img/sprite.svg#sprite-symbol-logo-view') no-repeat;
                                    background-size: 100% 100%;
                                }

                            }

                        }                        

                        &__onexclusive {

                            position: absolute;
                            bottom: 0;
                            left: 0;
                            z-index: 110;
                            width: 100%;
                            height: 38px;
                            border-top: $border-divider;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            text-decoration: none;
                            color: white;
                            transition: background-color 0.25s linear, color 0.25s linear;
                            background-color: rgba(0, 0, 0, 0.3);
                            cursor: pointer;

                            &:before {
                                content: '';
                                display: block;
                                width: 44px;
                                height: 38px;
                                background: url('../img/sprite.svg#sprite-hash-view') no-repeat;
                                margin-right: 7px;
                                background-size: 100% 100%;
                            }
    
                            &-container {
                            }
    
                            &-link {
                                display: block;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                z-index: 100;
                            }
    
                            &-contest {
                                font-family: 'Circe';
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 100%;
                                letter-spacing: 0.2px;
                            }
    
                            &-text {
                                font-family: 'Circe';
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 100%;
                                text-transform: uppercase;
                            }
    
                            &:hover {
    
                                background-color: $green-light;
                                color: $black;
    
                                &:before {
                                    background: url('../img/sprite.svg#sprite-hash-hover-view') no-repeat;
                                    background-size: 100% 100%;
                                }
    
                            }

                        }

                        &__background {

                            position: absolute;
                            top: 0;
                            right: 0;
                            z-index: 100;
                            border-left: $border-divider;

                            & picture,
                            & picture img {
                                display: block;
                                max-width: 100%;
                            }

                        }

                        &__buttons {

                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            z-index: 200;
                            display: flex;
                            align-items: stretch;
                            justify-content: flex-start;
                            height: 38px;

                            &-read {

                                width: 100px;
                                border-top: $border-divider;
                                color: #FFF;
                                text-align: center;
                                font-family: 'Circe';
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                text-align: center;
                                transition: all 0.25s linear;
                                margin-right: 13px;
                                padding: 1px 14px 1px;

                                &:hover,
                                &:active {
                                    background-color: $green-light;
                                    color: $black;
                                }

                            }

                            &-shop {

                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;
                                

                                &:before {
                                    content: '';
                                    display: block;
                                    width: 18.119px;
                                    height: 21.408px;
                                    background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                                    background-size: 100% 100%;
                                }

                            }

                        }

                        &__content {

                            position: relative;
                            border-right: $border-divider;
                            border-left: $border-divider;

                            &-title {

                                padding: 11px 105px 16px 15px;
                                border-bottom: $border-divider;
                                color: white;
                                font-family: 'Circe';
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 100%;
                                width: 395px;
                                position: relative;                            
    
                                & .content-title {
    
                                    &__logo {
    
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        z-index: 110;
                                        width: 99px;
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        border-left: $border-divider;
    
                                        & a {
                                            display: block;
                                            width: 83px;
                                            height: 23px;
                                            background: url('../img/sprite.svg#sprite-symbol-logo-view') no-repeat;
                                            background-size: 100% 100%;
                                        }
    
                                    }
    
                                }

                            }

                            &-text {
                                padding: 8px 105px 16px 15px;
                                color: white;
                                font-family: 'Circe';
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: 118%;
                                width: 395px;
                            }

                            &-read {

                                position: absolute;
                                bottom: 0;
                                right: 0;
                                z-index: 100;
                                width: 100px;
                                border-top: $border-divider;
                                border-left: $border-divider;
                                color: #FFF;
                                text-align: center;
                                font-family: 'Circe';
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                text-align: center;
                                transition: all 0.25s linear;

                                &:hover,
                                &:active {
                                    background-color: $green-light;
                                    color: $black;
                                }

                            }

                        }

                    }

                }

                &:nth-child(3) {

                    & .list-item {

                        &__picture {

                            position: relative;

                            &-shop {

                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;
                                position: absolute;
                                bottom: 7px;
                                left: 10px;
                                z-index: 100;                         

                                &:before {
                                    content: '';
                                    display: block;
                                    width: 18.119px;
                                    height: 21.408px;
                                    background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                                    background-size: 100% 100%;
                                }

                            }

                            & picture,
                            & picture img {
                                display: block;
                                height: 100%;
                            }

                        }

                        &__rail {

                            & picture,
                            & picture img {
                                display: block;
                                height: 100%;
                            }

                        }

                        &__logo {

                            width: 40px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            padding-top: 23px;
                            border-left: $border-divider;
                            border-right: $border-divider;
                            position: relative;

                            &-divider {
                                height: 1px;
                                border-bottom: $border-divider;
                                position: absolute;
                                left: 0;
                                top: 75px;
                                width: 100%;
                                z-index: 100;
                            }

                            &-item {
                                display: block;
                                width: 28px;
                                height: 27px;
                                background: url('../img/sprite.svg#sprite-logo-view') no-repeat;
                                background-size: 100% 100%;
                            }

                            &-shop {

                                width: 100%;
                                height: 37px;
                                border-top: $border-divider;
                                margin-top: auto;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: center;

                                &:before {
                                    content: '';
                                    display: block;
                                    width: 18.119px;
                                    height: 21.408px;
                                    background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                                    background-size: 100% 100%;
                                }

                            }


                        }

                    }

                }

            }

        }

    }

    .fullscreen-banner-mobile {

        display: flex;
        flex-direction: column;
        width: 100vw;
        min-width: 290px;
        height: 100vh;
        max-height: 800px;
        overflow: hidden;
        position: relative;
        background-color: $black;        

        &__viewport {

            &.--shop {
                bottom: -100%;
                height: 100%;

                & .fullscreen-banner-mobile__viewport-container {
                    flex-direction: column;
                }

            }

        }

        &__close {

            &.--about {
                width: 16px;
                height: 16px;
                top: 16px;
                right: 16px;
                background: url('../img/sprite.svg#sprite-close-mobile-white-view') no-repeat;
                background-size: 100% 100%;
            }

        }

        &__shop {

            &-item {

                &:first-child {
                    border-top-left-radius: 0px;
                }

                &:last-child {
                    border-top-right-radius: 0px;
                }

                & .shop-item {

                    &__picture {
                        
                        margin-bottom: 5px;

                        & picture,
                        & picture img {
                            display: block;
                            max-width: 100%;
                            max-height: 100%;
                            max-height: 236px;
                        }

                    }

                    &__content {

                        margin-top: auto;

                        &-category {
                            font-size: 12px;
                            font-weight: 300;
                            margin-bottom: 5px;
                        }

                        &-title {
                            font-size: 16px;
                            letter-spacing: -0.16px;
                        }
                        
                    }

                }

            }

        }

        &__about {

            padding: 30px 20px;
            background-color: $black;
            overflow-y: visible;

            &-title {
                color: white;
                font-family: 'Circe';
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                margin-bottom: 14px;
            }

            &-text {
                color: #FFF;
                font-family: 'Circe';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 137.5%;
            }

        }

        &__list {

            flex: 1;

            &-item {

                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;

                & .list-item {

                    &__header {
            
                        height: 104px;
                        width: 100%;
                        border-bottom: $border-divider;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: stretch;
                        justify-content: flex-start;
            
                        &-left,
                        &-right {
                            flex: 1 1 50%;
                            border-right: $border-divider;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
            
                        &-right {
                            border-right: none;
                        }
            
                        &-logos {

                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            & .header-logos {

                                &__lio {         
                                    position: relative;   
                                    width: 34px;
                                    height: 33px;
                                    position: relative;
                                    background: url('../img/sprite.svg#sprite-logo-view') no-repeat;
                                    background-size: 100% 100%;
                                    margin-bottom: 12px;                                    
                                }

                                &__symbol {
                                    position: relative; 
                                    width: 98px;
                                    height: 27.35px;
                                    position: relative;
                                    background: url('../img/sprite.svg#sprite-symbol-logo-view') no-repeat;
                                    background-size: 100% 100%; 
                                    
                                }

                            }
            
                            & a {
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 100;
                            }
            
                        }
            
                        &-read {
            
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
            
                            & span.text {
                                color: white;
                                text-align: center;
                                font-family: 'Circe';
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 137.5%;
                            }
            
                            & span.icon {
                                display: none;
                            }
            
                            /*&:hover {
            
                                background-color: $green-light;
            
                                & span {
                                    color: $black;
                                }
                                
                            }*/
            
                        }
            
                    }

                    &__picture {

                        flex: 1;
                        overflow: hidden;
                        position: relative;

                        &-container {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 100%;
                        }

                        & picture,
                        & picture img {
                            display: block;
                        }

                    }

                    &__footer {

                        display: flex;
                        flex-wrap: wrap;
                        align-items: stretch;
                        justify-items: flex-start;
                        position: absolute;
                        width: 100%;
                        height: 46px;
                        bottom: 0;
                        left: 0;
                        z-index: 200;
                        padding: 0;
                        border-top: $border-divider;
                        background-color: rgba(0, 0, 0, 0.3);

                        &-shop {

                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            flex: 0 0 46px;
                            width: 46px;
                            border-right: $border-divider;
                            cursor: pointer;                     
        
                            &:before {
                                content: '';
                                display: block;
                                width: 18px;
                                height: 21px;
                                background: url('../img/sprite.svg#sprite-bag-view') no-repeat;
                                background-size: 100% 100%;
                            }

                        }

                        &-onexclusive {

                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            flex: 1;
                            text-decoration: none;
                            color: white;
                            position: relative;

                            &:before {
                                content: '';
                                display: block;
                                width: 53.25px;
                                height: 46px;
                                background: url('../img/sprite.svg#sprite-hash-view') no-repeat;
                                margin-right: 8.5px;
                                background-size: 100% 100%;
                            }

                            & .footer-onexclusive {

                                &__container {
                                    position: relative;
                                    top: 1px;
                                }

                                &__link {
                                    display: block;
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                    z-index: 100;
                                }

                                &__contest {
                                    font-family: 'Circe';
                                    font-size: 12.105px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    letter-spacing: 0.242px;
                                }

                                &__text {
                                    text-align: center;
                                    font-family: 'Circe';
                                    font-size: 17px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 110%;
                                    text-transform: uppercase;
                                }

                            }

                            &:hover {

                                color: $black;
                                background: $green-light;

                                &:before {
                                    background: url('../img/sprite.svg#sprite-hash-hover-view') no-repeat;
                                    background-size: 100% 100%;
                                }

                            }

                        }

                    }

                    &__adv {                        
                        position: absolute;
                        bottom: 46px;
                        right: 0;
                        width: 54%;
                        color: #fff;
                        font-family: "Circe";
                        font-size: 7px;
                        font-weight: 300;
                        line-height: 122.727%;
                        padding: 5px;
                        background-color: rgba(9, 9, 9, 0.5);
                        z-index: 200;
                    }

                    &__about {                       

                        position: absolute;
                        height: calc(100% - 104px);
                        width: 100%;
                        overflow-y: scroll;
                        left: 0;
                        bottom: calc(-1 * calc(100% - 104px));
                        z-index: 210;
                        padding: 20px 15px;
                        background-color: $black;
                        transition: bottom 0.3s linear;

                        &-heading {

                            color: white;
                            font-family: 'Circe';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 22px;

                            & span {
                                text-decoration: underline;
                            }

                        }

                        &-divider {
                            margin: 20px 0;
                            height: 1px;
                            background-color: white;
                        }

                        &-text {

                            margin-bottom: 15px;

                            & p {

                                color: white;
                                font-family: 'Circe';
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: 133%;
                                margin-bottom: 10px;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                            }
                            
                        }

                        &-footing {
                            color: #6C6C6C;
                            font-family: 'Circe';
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 133%;
                        }

                    }

                    &__tags {

                        position: absolute;
                        top: 104px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 650px;
                        height: calc(100% - 150px);
                        z-index: 200;
                        opacity: 1;
                        transition: opacity 0.5s linear;

                        &.--open {
                            opacity: 1;
                        }
        
                        &-item {
    
                            width: 47px;
                            height: 47px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 100;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
    
                            & span.idle {
                                display: block;
                                width: 100%;
                                height: 100%;
                                background: url('../img/sprite.svg#sprite-tag-view') no-repeat;
                                background-size: 100% 100%;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                z-index: 102;
                                opacity: 1;
                                transition: opacity 0.5s ease-in;
                                cursor: pointer;
                            }
    
                            & span.active {
                                display: block;
                                width: 200%;
                                height: 200%;
                                background: url('../img/sprite.svg#sprite-tag-hover-view') no-repeat;
                                background-size: 100% 100%;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                transform-origin: center center;
                                z-index: 100;
                                opacity: 0;
                                transition: width 0.5s ease-in, height 0.5s ease-in, opacity 0.5s ease-in;
    
                            }
    
                            & span.idle:active,
                            & span.idle:hover {
    
                                opacity: 0;
    
                                & + span.active {
                                    opacity: 1;
                                    width: 100%;
                                    height: 100%;
                                }
    
                                & + span.active + span.text {
                                    opacity: 1;
                                }
    
                            }
    
                            &.--1 {             
                                top: 136px;
                                left: 197px;             
                            }
    
                            &.--2 {   
                                top: 401px;
                                left: 452px;           
                            }
    
                            &.--3 {     
                                top: 504px;
                                left: 250px;       
                            }
    
                            &.--4 {     
                                top: 166px;
                                left: 311px;    
                            }
    
                            &.--5 {     
                                top: 357px;
                                left: 396px;     
                            }
    
                            &.--6 {     
                                top: 530px;
                                left: 205px;     
                            }
    
                            &.--7 {                                                         
                                top: 130px;
                                left: 243px;       
                            }
    
                            &.--8 {     
                                top: 250px;
                                left: 148px;    
                            }
    
                            &.--9 {     
                                top: 344px;
                                left: 340px;     
                            }
    
                            &.--10 {     
                                top: 422px;
                                left: 170px;    
                            }
    
                            &.--11 {     
                                top: 590px;
                                left: 277px;   
                            }
    
                            &.--12 {     
                                top: 84px;
                                top: 59px;
                                left: 229px;   
                            }
    
                            &.--13 {     
                                top: 230px;
                                top: 205px;
                                left: 345px;  
                            }
    
                            &.--14 {     
                                top: 245px;
                                top: 220px;
                                left: 458px; 
                            }
    
                            &.--15 {     
                                top: 362px;
                                top: 337px;
                                left: 304px;  
                            }
    
                            &.--16 {     
                                top: 409px;
                                top: 384px;
                                left: 217px;
                            }
    
                            &.--17 {     
                                top: 543px;
                                top: 494px;
                                left: 418px;   
                            }
    
                            &.--18 {     
                                top: 601px;
                                top: 576px;
                                left: 203px;

                                top: 543px;
                                left: 173px;
                            }
    
                            &.--19 {     
                                top: 146px;
                                top: 121px;
                                left: 335px;  
                            }
    
                            &.--20 {     
                                top: 200px;
                                top: 175px;
                                left: 400px;  
                            }
    
                            &.--21 {     
                                top: 290px;
                                top: 265px;
                                left: 204px;  
                            }
    
                            &.--22 {     
                                top: 333px;
                                top: 308px;
                                left: 410px;  
                            }
    
                            &.--23 {     
                                top: 490px;
                                top: 465px;
                                left: 325px;  
                            }
    
                            &.--24 {     
                                top: 68px;
                                left: 271px;
                            }
    
                            &.--25 {     
                                top: 274px;
                                left: 348px; 
                            }
    
                            &.--26 {     
                                top: 442px;
                                left: 237px;  
                            }
    
                            &.--27 {     
                                top: 53px;
                                left: 278px; 
                            }
    
                            &.--28 {     
                                top: 156px;
                                left: 285px; 
                            }
    
                            &.--29 {     
                                top: 232px;
                                left: 219px;
                            }
    
                            &.--30 {     
                                top: 414px;
                                left: 382px;
                            }
    
                            &.--31 {     
                                top: 465px;
                                left: 186px; 
                            }
                            
                        }

                    }  

                }

                &.--open-about {

                    & .list-item {

                        &__about {
                            bottom: 0;
                        }

                        &__header {
            
                            &-read {
                
                                & span.text {
                                    display: none;
                                }
                
                                & span.icon {
                                    display: block;
                                    width: 26px;
                                    height: 26px;
                                    background: url('../img/sprite.svg#sprite-close-view') no-repeat;
                                    background-size: 100% 100%;
                                }
                
                                /*&:hover {
                
                                    background-color: $green-light;
                
                                    & span {
                                        color: $black;
                                    }
                                    
                                }*/
                
                            }

                        }

                    }

                }

            }

        }


    }


}