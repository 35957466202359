@font-face {
    font-family: 'Circe';
    /*src: url('../fonts/SBSansDisplay/SBSansDisplay-Thin.eot');
    src: url('../fonts/SBSansDisplay/SBSansDisplay-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Thin.woff2') format('woff2'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Thin.woff') format('woff'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Thin.ttf') format('truetype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Thin.svg') format('svg');*/
    src: url('../fonts/Circe/Circe-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('../fonts/Circe/Circe-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('../fonts/Circe/Circe-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('../fonts/Circe/Circe-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('../fonts/Circe/Circe-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('../fonts/Circe/Circe-ExtraBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}